import { Draggable } from "react-beautiful-dnd";
import { getTypeForContent } from "../utils/accessors.js";
import React from "react";
import { ContentItemEpisodeAction } from "../../../components/content-list/content-item.jsx";
import { format } from "date-fns";
import TranscodingStatusText from "../../components/transcoding-status-text.jsx";
import { getDurationInSecondsWithAdBreaks } from "../utils/create-ad-breaks.js";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import { secondsToHourMinutesSeconds } from "../../../common/duration-formatting.js";

function AddToSchedule({ clicked, onClick, program, earliestTime = null, latestTime = null }) {
  return (
    <button
      className="btn--text-primary"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(program, earliestTime, latestTime);
        return true;
      }}
    >
      {clicked ? <CheckBoxRoundedIcon /> : <AddBoxRoundedIcon />}
    </button>
  );
}

function DraggableContentListItem({
  program,
  index,
  addProgram,
  selectProgram,
  onPlay,
  onActions = {},
  transcodingTypes = [],
  isDragDisabled = false,
  onContentDrag = () => null,
  blockFiltering = { active: false },
}) {
  const [isAdded, setAdded] = React.useState(false);

  function onProgramClick() {
    selectProgram(program);
    onPlay(program.video?.asset_id);
  }

  function addProgramWrapper(...params) {
    setAdded(true);
    addProgram(...params);
  }

  const type = getTypeForContent(program);
  const guid = program[`${type}_guid`];
  const hasPrevScheduledTime = !!program.latest_channel_plan_program?.program_start;
  const cuepoints = program?.cuepoints
    ? program.cuepoints.map((contentCuepoint) => ({
        ad_break: contentCuepoint.ad_break,
        cuepoint: contentCuepoint.cuepoint,
      }))
    : [];

  function isNotValid(program) {
    return !program.duration && !program.asset_duration;
  }

  return (
    <Draggable key={guid} draggableId={guid} index={index} isDragDisabled={isNotValid(program) || isDragDisabled}>
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
          }}
          onClick={onProgramClick}
          onMouseDown={onContentDrag}
        >
          <span
            className={`content-item ${isNotValid(program) ? "content-item--warning" : ""}`}
            {...(isNotValid(program)
              ? {
                  ["aria-label"]: "No video found for this content",
                  ["data-balloon-pos"]: index === 0 ? "down" : "up",
                }
              : {})}
          >
            <span className="content-item__description">
              <span className="content-item__heading">{program.formatted_name}</span>
              <span className="content-item__sub-heading">
                <span>
                  {`${secondsToHourMinutesSeconds(getDurationInSecondsWithAdBreaks(program.trimmed_duration ?? program.duration, []))} (${secondsToHourMinutesSeconds(
                    getDurationInSecondsWithAdBreaks(program.trimmed_duration ?? program.duration, cuepoints),
                  )})`}
                </span>
              </span>
              {hasPrevScheduledTime && program.latest_channel_plan_program ? (
                <span
                  className="content-item__sub-heading content-item__sub-heading--hoverable"
                  aria-label={`Last Scheduled at ${format(new Date(program.latest_channel_plan_program.program_start), "Y-MM-dd HH:mm:ss")}`}
                  data-balloon-pos="right"
                  data-balloon-length="medium"
                >
                  {format(new Date(program.latest_channel_plan_program.program_start), "y-MM-dd")}
                </span>
              ) : null}

              <span className="content-item__provider">
                <span className="content-item__provider__inner">
                  {program.feature_id ? program.title.provider.provider_name : null}
                  {program.episode_id ? program.season.series.title.provider.provider_name : null}
                  {program.promo_id ? program.provider.provider_name : null}
                </span>
              </span>

              <span className="content-item__transcoding-status">
                {transcodingTypes.includes("hls") ? (
                  <TranscodingStatusText
                    status={program?.content_video?.hls_status}
                    popupPosition="left"
                    type={"hls"}
                  />
                ) : null}
                {transcodingTypes.includes("dash") ? (
                  <TranscodingStatusText
                    status={program?.content_video?.dash_status}
                    popupPosition="left"
                    type={"dash"}
                  />
                ) : null}
              </span>
            </span>
            <div className="content-item__col">
              <span className="content-item__actions">
                <span className={`content-item__action ${isNotValid(program) ? "content-item__action--disabled" : ""}`}>
                  <ContentItemEpisodeAction program={program} type={type} onClick={onActions.getEpisodesForSeries} />
                </span>
                <span className={`content-item__action ${isNotValid(program) ? "content-item__action--disabled" : ""}`}>
                  <AddToSchedule
                    clicked={isAdded}
                    onClick={addProgramWrapper}
                    program={program}
                    earliestTime={blockFiltering.active ? blockFiltering.start : null}
                    latestTime={blockFiltering.active ? blockFiltering.end : null}
                  />
                </span>
              </span>
              <span className="content-item__rating">{program.rating ? program.rating.rating : "-"}</span>
            </div>
          </span>
        </li>
      )}
    </Draggable>
  );
}

export default DraggableContentListItem;
