import React from "react";
import Select from "../../../common/select.jsx";

function ProviderFilter({ selectedProviders, providers, onChange }) {
  React.useEffect(() => {
    let patched = false;
    let fixedSelectedList = selectedProviders.map((provider) => {
      let label = provider.label;
      if (!label) {
        label = providers.find((prov) => prov.provider_guid === provider.value)?.provider_name ?? "-";

        if (label !== "-") {
          patched = true;
        }
      }

      return {
        value: provider.value ?? "",
        label,
      };
    });

    if (patched) {
      onChange(fixedSelectedList);
    }
  }, [selectedProviders, onChange, providers]);

  return (
    <Select
      isSearchable={true}
      placeholder={"Provider"}
      name={"provider"}
      options={providers.map((p) => ({
        label: p.provider_name,
        value: p.provider_guid,
      }))}
      value={selectedProviders}
      onChange={(input) => onChange([input])}
      containerClass="react-select-container--light react-select-container--small"
      // isMulti={true}
    />
  );
}

export default ProviderFilter;
