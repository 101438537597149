import React from "react";
import ReactModal from "react-modal";
import axios from "../../../../requests/axios.js";
import format from "date-fns/format";
import Loader from "../../../../common/loader.jsx";
import { toast } from "react-toastify";

function TrashPlanDialog({ isOpen, onClose, onSuccess, planDate, channelId, updateActiveDate, breakSource }) {
  const [dateToClear, setDateToClear] = React.useState(new Date());
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setDateToClear(planDate);
  }, [planDate]);

  function onSubmit() {
    setIsLoading(true);

    // overwrite with an 'empty' plan object
    const payload = {};
    payload.plan_breaks = breakSource;
    payload.programs = [];
    payload.filters = [];

    axios
      .put(`/api/channels/${channelId}/plans/${format(dateToClear, "y-MM-dd")}`, payload)
      .then((response) => {
        onClose();
        onSuccess(response.data);
        toast.success("Plan cleared successfully");
        updateActiveDate(dateToClear);
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage("There was an error clearing the plan, please contact support.");
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__content">
          <div className="v-react-modal__heading">Delete Plan Confirmation</div>
          <div className="v-react-modal__body modal-body">
            Are you sure you want to remove this plan?
            <br />
            It will not be possible for you to reverse this action.
            {errorMessage ? <p className="u-fc--warning">{errorMessage}</p> : null}
          </div>
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            [
              <button className="btn btn--primary" onClick={onClose} key="trash-plan-dialog-cancel">
                Nevermind
              </button>,
              <button className="btn btn--inverse-primary" onClick={onSubmit} key="trash-plan-dialog-submit">
                Yes I am sure
              </button>,
            ]
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default TrashPlanDialog;
