import React from "react";
import { getHeightStyleFromSeconds } from "../../components/vertical-scheduler/helpers/style-helpers";

const BUTTON_HEIGHT_SECONDS = 5 * 60;

function TemplateAddBlockButton({ addBlock, secondsPerSection, pixelsPerSection, isTemplateItem = false }) {
  return (
    <div
      className={`vertical-scheduler__add-block ${isTemplateItem ? "vertical-scheduler__add-block--list-item" : ""}`}
      style={{
        height: getHeightStyleFromSeconds(BUTTON_HEIGHT_SECONDS, secondsPerSection, pixelsPerSection),
      }}
      onClick={addBlock}
    >
      <div className="vertical-scheduler-calendar__pill vertical-scheduler-calendar__pill--tertiary">
        <span className="vertical-scheduler-calendar__pill__text">Create Block</span>
      </div>
    </div>
  );
}

export default TemplateAddBlockButton;
