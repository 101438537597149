import React from "react";
import ReactModal from "react-modal";

export default function RemoveBreakWithFilterDialog({ isOpen, segment, deleteIndex, onClose, onConfirm }) {
  function onSubmit() {
    onClose();
    onConfirm(segment, deleteIndex);
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__content">
          <div className="v-react-modal__heading">Confirm Delete Break</div>
          <div className="v-react-modal__body">
            Are you sure you want to delete this break?
            <br />
            The filter in the following segment will also be deleted.
          </div>
        </div>
      </div>
      <div className="v-react-modal__footer">
        <button className="btn btn--primary" onClick={onClose} key="btn-back">
          Cancel
        </button>
        <button className="btn btn--inverse-primary" onClick={onSubmit} key="btn-yes">
          Delete
        </button>
      </div>
    </ReactModal>
  );
}
