import React from "react";
import useApiRequest from "../../../../hooks/use-api-request.js";
import Loader from "../../../../common/loader.jsx";
import ContentCard from "../../components/content-card.jsx";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import useSpreadState from "../../../../hooks/use-spread-state.js";
import { AppHeadingActions } from "../../../../layout/parts/app-heading.jsx";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { toast } from "react-toastify";
import axios from "../../../../requests/axios.js";
import DialogForm from "../../../../components/dialog-form.jsx";
import GenericDeleteDialog from "../../../components/dialogs/generic-delete-dialog.jsx";
import Select from "../../../../common/select.jsx";

let RATING_OPTIONS = {
  mpaa: [],
  us_tv: [],
  bbfc: [],
};

let GENRE_OPTIONS = {
  imdb: [],
  freeview: [],
};

export default function SeasonItem({ guid }) {
  const { isLoading, response } = useApiRequest(`/api/seasons/${guid}`, { cache: true });
  const [isEditing, setEditing] = React.useState(false);
  const [form, updateForm] = useSpreadState({});
  const [showCastCrewDialog, setShowCastCrew] = React.useState(false);
  const [isTrashing, setTrashing] = React.useState(false);
  const [isSaving, setSaving] = React.useState(false);
  const [season, setSeason] = React.useState();
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    if (response.data) {
      setSeason(response.data);
    }
  }, [response]);

  React.useEffect(() => {
    if (season) {
      setData({ ...season, ...(isEditing ? form : {}) });
    }
  }, [form, season, isEditing]);

  const getActiveRating = React.useCallback(
    (scheme) => {
      if (!data.rating) {
        return null;
      }

      const rating = RATING_OPTIONS[scheme].filter((i) => i.value === data.rating[`${scheme}_rating_id`]);
      if (!rating.length) {
        return null;
      }

      return rating[0];
    },
    [data.rating],
  );

  const getActiveGenre = React.useCallback(
    (scheme) => {
      let genre = null;
      switch (scheme) {
        case "imdb_genre":
          genre = GENRE_OPTIONS["imdb"].find((i) => i.value === data.imdb_genre?.value);
          break;
        case "imdb_subgenre":
          var primaryGenre = getActiveGenre("imdb_genre");
          //  subgenre is linked to a primary genre
          if (!primaryGenre || !data.imdb_subgenre) {
            break;
          }

          genre = getActiveGenre("imdb_genre").subgenres.find((i) => i.value === data.imdb_subgenre?.value);
          break;
        case "freeview_genre":
          genre = GENRE_OPTIONS["freeview"].find((i) => i.value === data.freeview_genre?.value);
          break;
      }

      if (!genre) {
        return null;
      }

      return genre;
    },
    [data.imdb_genre, data.imdb_subgenre, data.freeview_genre],
  );

  // fill out dropdown list options
  React.useEffect(() => {
    if (!RATING_OPTIONS.length) {
      axios
        .get("/api/ratings")
        .then((resp) => {
          RATING_OPTIONS = resp.data;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (!GENRE_OPTIONS.length) {
      axios
        .get("/api/genres")
        .then((resp) => {
          const imdbGenres = resp.data.imdb.map((genre) => ({
            label: genre.label,
            value: genre.value,
            subgenres: genre.subgenres.map((subgenre) => ({ label: subgenre.label, value: subgenre.value })),
          }));

          const freeviewGenres = resp.data.freeview.map((genre) => ({
            label: genre.label,
            value: genre.value,
          }));

          GENRE_OPTIONS = {
            imdb: imdbGenres,
            freeview: freeviewGenres,
          };
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  //  set default form values
  React.useEffect(() => {
    if (season) {
      updateForm({
        imdb_genre: season?.genre?.imdb?.imdb_genre
          ? { label: season.genre.imdb.imdb_genre.label, value: season.genre.imdb.imdb_genre.value }
          : null,
        imdb_subgenre: season?.genre?.imdb?.imdb_subgenre
          ? { label: season.genre.imdb.imdb_subgenre.label, value: season.genre.imdb.imdb_subgenre.value }
          : null,
        freeview_genre: season?.genre?.freeview?.freeview_genre
          ? {
              label: season.genre.freeview.freeview_genre.label,
              value: season.genre.freeview.freeview_genre.value,
            }
          : null,
        summary: {
          long_summary: season.summary?.long_summary ?? "",
          short_summary: season.summary?.short_summary ?? "",
        },
      });
    }
  }, [season, updateForm]);

  if (isLoading) {
    return (
      <div className="content-page__loader">
        <Loader />
      </div>
    );
  }

  function onSaveEdits() {
    setSaving(true);

    const payload = { ...form };
    // remove subgenre from payload if not set
    if (!payload.imdb_subgenre) {
      delete payload.imdb_subgenre;
    }

    axios
      .post(`/api/seasons/${guid}`, payload)
      .then((resp) => {
        toast.success("Season successfully updated");
        setEditing(false);
        setSeason(resp.data);
      })
      .catch((error) => {
        toast.error("Error attempting to update Season");
        console.error(error);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function getSubgenresForGenre(genre) {
    if (!genre) {
      return [];
    }

    const foundGenre = GENRE_OPTIONS.imdb.find((genreItem) => genreItem.value === genre.value);
    if (!foundGenre) {
      return [];
    }

    return foundGenre.subgenres;
  }

  function removeCastCrew(index) {
    updateForm({
      cast_crew: [...data.cast_crew.slice(0, index), ...data.cast_crew.slice(index + 1)],
    });
  }

  function addCastCrew(castCrew) {
    updateForm({
      cast_crew: [...data.cast_crew, castCrew],
    });
  }

  function updateSummary(label, event) {
    const newSummary = event.target.value;
    updateForm({
      summary: {
        ...form.summary,
        [label]: newSummary,
      },
    });
  }

  function updateRating(label, event) {
    const newRating = event.value;
    updateForm({
      rating: {
        ...data.rating,
        [`${label}_rating_id`]: newRating,
      },
    });
  }

  function updateGenre(type, input) {
    switch (type) {
      case "imdb_genre":
        updateForm({
          imdb_genre: input,
          imdb_subgenre: null,
        });
        break;

      case "imdb_subgenre":
        updateForm({
          imdb_subgenre: input,
        });
        break;

      case "freeview_genre":
        updateForm({
          freeview_genre: input,
        });
        break;
    }
  }

  return season ? (
    <div>
      <div className="tabbed-content__actions">
        {isSaving ? (
          <Loader height={35} width={35} />
        ) : (
          <AppHeadingActions
            rightLight
            rightActions={[
              {
                when: isEditing,
                icon: SaveRoundedIcon,
                balloonLabel: "Save",
                onClick: onSaveEdits,
              },
              {
                when: isEditing,
                icon: CancelRoundedIcon,
                balloonLabel: "Cancel",
                onClick: () => {
                  setEditing(false);
                },
              },
              {
                when: !isEditing && season.season_active === 1,
                icon: DeleteForeverRoundedIcon,
                balloonLabel: "Mark as inactive",
                onClick: () => setTrashing(true),
              },
              {
                when: !isEditing,
                icon: EditRoundedIcon,
                balloonLabel: "Edit",
                onClick: () => setEditing(true),
              },
            ]}
          />
        )}
      </div>
      <div className="gw u-margin--block-end">
        <div className="g g--1-of-2">
          <ContentCard>
            <div className="content-page__section">
              <div className="content-page__list">
                <div className="content-page__list__item">
                  <div className="content-page__list__label">IMDb Genre</div>
                  {isEditing ? (
                    <Select
                      placeholder="Select a Genre"
                      name="genre"
                      options={GENRE_OPTIONS.imdb}
                      value={getActiveGenre("imdb_genre")}
                      onChange={(input) => updateGenre("imdb_genre", input)}
                      classNamePrefix="react-select"
                      containerClass="react-select-container--small react-select-container--light"
                      menuPlacement="auto"
                    />
                  ) : (
                    <div className="content-page__list__value">{season.genre?.imdb?.imdb_genre?.label ?? "N/A"}</div>
                  )}
                </div>
                <div className="content-page__list__item">
                  <div className="content-page__list__label">IMDb Subgenre</div>
                  {isEditing ? (
                    <Select
                      placeholder="Select a Genre"
                      name="subgenre"
                      options={getSubgenresForGenre(getActiveGenre("imdb_genre"))}
                      value={getActiveGenre("imdb_subgenre")}
                      onChange={(input) => updateGenre("imdb_subgenre", input)}
                      classNamePrefix="react-select"
                      containerClass="react-select-container--small react-select-container--light"
                      menuPlacement="auto"
                    />
                  ) : (
                    <div className="content-page__list__value">{season.genre?.imdb?.imdb_subgenre?.label ?? "N/A"}</div>
                  )}
                </div>
                <div className="content-page__list__item">
                  <div className="content-page__list__label">Freeview Genre</div>
                  {isEditing ? (
                    <Select
                      placeholder="Select a Genre"
                      name="genre"
                      options={GENRE_OPTIONS.freeview}
                      value={getActiveGenre("freeview_genre")}
                      onChange={(input) => updateGenre("freeview_genre", input)}
                      classNamePrefix="react-select"
                      containerClass="react-select-container--small react-select-container--light"
                      menuPlacement="auto"
                    />
                  ) : (
                    <div className="content-page__list__value">
                      {season.genre?.freeview?.freeview_genre?.label ?? "N/A"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ContentCard>
        </div>
        <div className="g g--1-of-2">
          <ContentCard>
            <div className="content-page__section">
              <div className="content-page__list">
                <div className="content-page__list__item">
                  <div className="content-page__list__label">BBFC Rating</div>
                  {isEditing ? (
                    <Select
                      options={RATING_OPTIONS.bbfc}
                      placeholder="Select a Rating"
                      name="bbfc_rating"
                      value={getActiveRating("bbfc")}
                      onChange={(input) => updateRating("bbfc", input)}
                      classNamePrefix="react-select"
                      containerClass="react-select-container--small react-select-container--light"
                      menuPlacement="auto"
                    />
                  ) : (
                    <div className="content-page__list__value">{season.rating?.bbfc_rating ?? "N/A"}</div>
                  )}
                </div>
                <div className="content-page__list__item">
                  <div className="content-page__list__label">MPAA Rating</div>
                  {isEditing ? (
                    <Select
                      options={RATING_OPTIONS.mpaa}
                      placeholder="Select a Rating"
                      name="mpaa_rating"
                      value={getActiveRating("mpaa")}
                      onChange={(input) => updateRating("mpaa", input)}
                      classNamePrefix="react-select"
                      containerClass="react-select-container--small react-select-container--light"
                      menuPlacement="auto"
                    />
                  ) : (
                    <div className="content-page__list__value">{season.rating?.mpaa_rating ?? "N/A"}</div>
                  )}
                </div>
                <div className="content-page__list__item">
                  <div className="content-page__list__label">US-TV Rating</div>
                  {isEditing ? (
                    <Select
                      options={RATING_OPTIONS.us_tv}
                      placeholder="Select a Rating"
                      name="us_tv_rating"
                      value={getActiveRating("us_tv")}
                      onChange={(input) => updateRating("us_tv", input)}
                      classNamePrefix="react-select"
                      containerClass="react-select-container--small react-select-container--light"
                      menuPlacement="auto"
                    />
                  ) : (
                    <div className="content-page__list__value">{season.rating?.us_tv_rating ?? "N/A"}</div>
                  )}
                </div>
              </div>
            </div>
          </ContentCard>
        </div>
      </div>
      <div className="gw">
        <div className="g g--1-of-1">
          <ContentCard>
            <div className="gw">
              <div className="g g--1-of-2">
                <h3 className="content-page__sub-title">Short Summary</h3>
                {isEditing ? (
                  <textarea
                    value={data.summary?.short_summary ?? "None"}
                    onChange={updateSummary.bind(null, "short_summary")}
                    maxLength={255}
                  />
                ) : (
                  <div className="content-page__text">{season.summary?.short_summary ?? "N/A"}</div>
                )}
                <h3 className="content-page__sub-title">Long Summary</h3>
                {isEditing ? (
                  <textarea
                    value={data.summary?.long_summary ?? "None"}
                    onChange={updateSummary.bind(null, "long_summary")}
                  />
                ) : (
                  <div className="content-page__text">{season.summary?.long_summary ?? "N/A"}</div>
                )}
              </div>
              <div className="g g--1-of-2">
                <div className="content-page__sub-title">
                  Cast & Crew
                  {isEditing ? (
                    <button className="btn btn--icon" onClick={() => setShowCastCrew(true)}>
                      <AddCircleRoundedIcon />
                    </button>
                  ) : null}
                </div>
                {isEditing ? (
                  <div className="cast-crew">
                    {data.cast_crew?.map((person, index) => (
                      <div className="cast-crew__item" key={index}>
                        <div className="cast-crew__role">{person.role}</div>
                        <div className="cast-crew__name">{person.name}</div>
                        {isEditing ? (
                          <div className="cast-crew__actions">
                            <button className="cast-crew__action-btn" onClick={() => removeCastCrew(index)}>
                              <CancelRoundedIcon />
                            </button>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                ) : season.cast_crew?.length ? (
                  <div className="content-page__list">
                    {season.cast_crew.map((person, index) => (
                      <div className="content-page__list__item" key={index}>
                        <div className="content-page__list__label">{person.role}</div>
                        <div className="content-page__list__value">{person.name}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  "N/A"
                )}
              </div>
            </div>
          </ContentCard>
        </div>
      </div>
      <DialogForm
        isOpen={showCastCrewDialog}
        onClose={() => setShowCastCrew(false)}
        onCancel={() => setShowCastCrew(false)}
        onSubmit={addCastCrew}
        title="Add Cast / Crew"
        fields={[
          {
            label: "Role",
            type: "text",
            name: "role",
          },
          {
            label: "Name",
            type: "text",
            name: "name",
          },
        ]}
      />
      <GenericDeleteDialog
        isOpen={isTrashing}
        body={
          "This will mark this season and all of it's related content as inactive, are you sure you wish to continue?"
        }
        onClose={() => {
          setTrashing(false);
        }}
        endpoint={`/api/seasons/${guid}`}
        onComplete={(result, error) => {
          if (error) {
            console.error(error);
          } else if (result) {
            setTrashing(false);
            toast.success("Successfully marked this season as inactive.");
          }
        }}
      />
    </div>
  ) : null;
}
