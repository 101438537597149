import React from "react";
import EditablePillList from "../../../components/editable-pill-list.jsx";
import { BASE_URL } from "../../../requests/api-requests.js";
import Dropzone from "../../components/dropzone.jsx";
import axios from "../../../requests/axios.js";
import ContentCard from "../.././library/components/content-card.jsx";
import ChannelFormDistribution from "./channel-form-distribution.jsx";

const MAX_CHANNEL_IMAGE_SIZE_IN_BYTES = 128000000; // currently 128MB

const editablePlayoutFields = ["manifest_override", "default_slate_reference"];

function ChannelForm({
  action = "create",
  channel = {},
  providers,
  distributionTypes,
  ssaiTypes,
  form,
  setForm,
  errors,
  setErrors,
}) {
  const [addGenreText, setAddGenreText] = React.useState("");
  const [addRegionText, setAddRegionText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState({});
  const [breaks, setBreaks] = React.useState([]);

  const updateForm = React.useCallback(
    (key, value) => {
      setForm((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    [setForm],
  );

  React.useEffect(() => {
    const initialBreaks =
      action === "edit" ? channel.channel_breaks : [{ start: "00:00:00", end: "24:00:00", type: "channel" }];

    const breakObject = Array.from(Array(24).keys()).map((i) => {
      const formattedHour = `${i}`.padStart(2, "0") + ":00:00";
      const breakIndex = initialBreaks ? initialBreaks.findIndex((br) => br.start === formattedHour) : -1;
      return {
        start: formattedHour,
        end: breakIndex !== -1 ? initialBreaks[breakIndex].end : null,
        checked: breakIndex !== -1,
        disabled: formattedHour === "00:00:00",
      };
    });

    setBreaks(breakObject);
  }, [channel.channel_breaks, action]);

  React.useEffect(() => {
    if (breaks.length) {
      updateForm("channel_breaks", breaks);
    }
  }, [breaks, updateForm]);

  function toggleChannelBreak(index) {
    setBreaks([
      ...breaks.slice(0, index),
      {
        ...breaks[index],
        checked: !breaks[index].checked,
      },
      ...breaks.slice(index + 1),
    ]);
  }

  function handleChange(key, e) {
    setForm((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      };
    });
  }

  function handleImageUpload(image, key) {
    setErrors({});

    if (image.size > MAX_CHANNEL_IMAGE_SIZE_IN_BYTES) {
      setErrors({ images: "Error uploading image. File too large." });
      return;
    }

    setIsLoading((prev) => ({
      ...prev,
      [key]: true,
    }));

    const data = new FormData();
    data.append("image", image, image.name);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${BASE_URL}/api/images/upload`, data, config)
      .then((response) => {
        if (response && response.data) {
          setForm((prev) => ({
            ...prev,
            [key]: response.data.serve_url,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() =>
        setIsLoading((prev) => ({
          ...prev,
          [key]: false,
        })),
      );
  }

  function onImageRemove(key) {
    const newForm = { ...form };
    newForm[key] = null;

    setForm({ ...newForm });
  }

  function updateAddGenreText(e) {
    setAddGenreText(e.target.value);
  }

  function updateAddRegionText(e) {
    setAddRegionText(e.target.value);
  }

  function updateGenres(list) {
    updateForm("genre", list);
  }

  function onGenreRemove(index, e) {
    e.preventDefault();
    updateGenres([...form.genre.slice(0, index), ...form.genre.slice(index + 1)]);
  }

  function addGenre(genre, e) {
    e.preventDefault();
    if (genre) {
      updateGenres([...form.genre, genre]);
    }
    setAddGenreText("");
  }

  function updateRegions(list) {
    updateForm("regions", list);
  }

  function onRegionRemove(index, e) {
    e.preventDefault();
    updateRegions([...form.regions.slice(0, index), ...form.regions.slice(index + 1)]);
  }

  function addRegion(region, e) {
    e.preventDefault();
    if (region) {
      updateRegions([...form.regions, region]);
    }
    setAddRegionText("");
  }

  function addPlayout() {
    setForm((current) => ({
      ...current,
      playouts: [
        ...current.playouts,
        {
          channel_id: channel?.channel_id ?? null,
          default_slate_reference: "",
          destination: "media-tailor",
          destination_output: "hls",
          destination_reference: "",
          destination_source_location: "",
          destination_maximum_programs: 200,
          manifest_override: "",
          distributions: [],
        },
      ],
    }));
  }

  function addPlayoutDistribution(playoutIndex) {
    setForm((prev) => {
      return {
        ...prev,
        playouts: [
          ...prev.playouts.slice(0, playoutIndex),
          {
            ...prev.playouts[playoutIndex],
            distributions: [
              ...prev.playouts[playoutIndex].distributions,
              {
                channel_playout_id: prev.playouts[playoutIndex].channel_playout_id,

                external_distribution_type: "",
                external_distribution_label: "",
                external_distribution_id: "",
                external_distribution_base_url: "",

                platform: "",
                platform_epg_url: "",

                ssai_type: "",
                ssai_label: "",
                ssai_id: "",

                springserve_router_id: "",
                springserve_router_label: "",
              },
            ],
          },
          ...prev.playouts.slice(playoutIndex + 1, prev.playouts.length),
        ],
      };
    });
  }

  function handleProviderChange(providerId, e) {
    setForm((prev) => ({
      ...prev,
      provider_ids: e.target.checked
        ? [...form.provider_ids].concat([providerId])
        : [...form.provider_ids].filter((id) => id !== providerId),
    }));
  }

  function handlePlayoutFieldChange(index, field, e) {
    if (editablePlayoutFields.includes(field) || !form.playouts[index].channel_playout_id) {
      setForm((current) => ({
        ...current,
        playouts: [
          ...current.playouts.slice(0, index),
          {
            ...current.playouts[index],
            [field]: e.target.value,
          },
          ...current.playouts.slice(index + 1),
        ],
      }));
    }
  }

  function doesPlayoutHaveError(id, error) {
    return errors.playouts && errors.playouts[id] && errors.playouts[id][error];
  }

  function getPlayoutError(id, error) {
    if (doesPlayoutHaveError(id, error)) {
      return errors.playouts[id][error];
    }
    return null;
  }

  function canEditField(playout, field) {
    if (!playout.channel_playout_id) {
      return true;
    }

    return editablePlayoutFields.includes(field);
  }

  function handleDistributionChange(playoutIndex, distributionIndex, key, value) {
    setForm((prev) => {
      const changes = {
        [key]: value,
        ...(key === "external_distribution_type" && value === "hbbtv"
          ? {
              external_distribution_label: "",
              external_distribution_id: "",
            }
          : {}),
        ...(key === "external_distribution_type" && value !== "hbbtv"
          ? {
              external_distribution_base_url: "",
            }
          : {}),
        ...(key === "ssai_type" && value === "client-side"
          ? {
              ssai_label: "",
              ssai_id: "",
            }
          : {}),
      };

      return {
        ...prev,
        playouts: [
          ...prev.playouts.slice(0, playoutIndex),
          {
            ...prev.playouts[playoutIndex],
            distributions: [
              ...prev.playouts[playoutIndex].distributions.slice(0, distributionIndex),
              {
                ...prev.playouts[playoutIndex].distributions[distributionIndex],
                ...changes,
              },
              ...prev.playouts[playoutIndex].distributions.slice(distributionIndex + 1),
            ],
          },
          ...prev.playouts.slice(playoutIndex + 1),
        ],
      };
    });
  }

  function removePlayoutDistribution(playoutIndex, distributionIndex) {
    setForm((prev) => {
      return {
        ...prev,
        playouts: [
          ...prev.playouts.slice(0, playoutIndex),
          {
            ...prev.playouts[playoutIndex],
            distributions: [
              ...prev.playouts[playoutIndex].distributions.slice(0, distributionIndex),
              ...prev.playouts[playoutIndex].distributions.slice(distributionIndex + 1),
            ],
          },
          ...prev.playouts.slice(playoutIndex + 1),
        ],
      };
    });
  }

  return (
    <React.Fragment>
      <form>
        <div className="island--smaller--block-end">
          <div className="gw">
            <div className="g g--2-of-3">
              <ContentCard>
                <div className="form-field">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange.bind(null, "name")}
                    defaultValue={action === "edit" ? form.name : ""}
                    disabled={action === "edit"}
                    readOnly={action === "edit"}
                  />
                  {errors.hasOwnProperty("name") ? <div className="form-field__error">{errors.name}</div> : null}
                </div>

                <div className="form-field">
                  <label>Display Name</label>
                  <input
                    type="text"
                    name="display_name"
                    onChange={handleChange.bind(null, "display_name")}
                    defaultValue={action === "edit" ? form.display_name : ""}
                  />
                  {errors.hasOwnProperty("display_name") ? (
                    <div className="form-field__error">{errors.display_name}</div>
                  ) : null}
                </div>

                <div className="form-field">
                  <label>Channel URL</label>
                  <input
                    type="text"
                    name="url"
                    onChange={handleChange.bind(null, "url")}
                    defaultValue={action === "edit" ? form.url : ""}
                  />
                  {errors.hasOwnProperty("url") ? <div className="form-field__error">{errors.url}</div> : null}
                </div>

                <div className="form-field">
                  <label>Genres</label>
                  <EditablePillList
                    list={form.genre || []}
                    onRemove={onGenreRemove}
                    onAdd={addGenre.bind(null, addGenreText)}
                    editing={true}
                    emptyMessage="No genres saved"
                  />
                  <input type="text" name="addGenre" onChange={updateAddGenreText} value={addGenreText} />
                </div>

                <div className="form-field">
                  <label>Language</label>
                  <input
                    type="text"
                    name="language"
                    onChange={handleChange.bind(null, "language")}
                    defaultValue={action === "edit" ? form.language : ""}
                  />
                  {errors.hasOwnProperty("language") ? (
                    <div className="form-field__error">{errors.language}</div>
                  ) : null}
                </div>

                <div className="form-field">
                  <label>Regions</label>
                  <EditablePillList
                    list={form.regions || []}
                    onRemove={onRegionRemove}
                    onAdd={addRegion.bind(null, addRegionText)}
                    editing={true}
                    emptyMessage="No regions saved"
                  />
                  <input type="text" name="addRegion" onChange={updateAddRegionText} value={addRegionText} />
                </div>

                <div className="form-field">
                  <label>Rating</label>
                  <input
                    type="text"
                    name="rating"
                    onChange={handleChange.bind(null, "rating")}
                    defaultValue={action === "edit" ? form.rating : ""}
                  />
                  {errors.hasOwnProperty("rating") ? <div className="form-field__error">{errors.rating}</div> : null}
                </div>

                <div className="form-field">
                  <label>Description</label>
                  <textarea
                    name="description"
                    onChange={handleChange.bind(null, "description")}
                    defaultValue={action === "edit" ? form.description : ""}
                    rows={6}
                  />
                  {errors.hasOwnProperty("description") ? (
                    <div className="form-field__error">{errors.description}</div>
                  ) : null}
                </div>
              </ContentCard>
            </div>
            <div className="g g--1-of-3">
              <ContentCard>
                <div className="form-block">
                  <div className="form-title">EPG Images</div>
                  <div className="form-images">
                    {["landscape", "portrait", "square"].map((imageType) => {
                      const imageExists = form.hasOwnProperty(imageType.toLowerCase()) && form[imageType.toLowerCase()];
                      return (
                        <div
                          className={`form-field__image form-field__image--${imageType}`}
                          key={`image__${imageType.toLowerCase()}`}
                        >
                          <div className="form-image-container">
                            <Dropzone
                              addFileToForm={handleImageUpload}
                              imageType={imageType}
                              imageExists={imageExists}
                              isLoading={isLoading[imageType]}
                              imageUrl={form[imageType] ?? ""}
                              removeImage={onImageRemove}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {errors.hasOwnProperty("images") ? <div className="form-field__error">{errors.images}</div> : null}
                </div>
              </ContentCard>
            </div>
          </div>
        </div>
        <div className="island--smaller--block-end">
          <div className="gw">
            <div className="g g--1-of-1">
              <ContentCard>
                <div className="form-block form-block--no-mar">
                  <div className="form-title">Providers</div>
                  <div className="form-field form-field--rows">
                    {providers &&
                      providers.data.map((provider, key) => (
                        <div className="provider-checkbox" key={key}>
                          <input
                            className="form-checkbox"
                            type="checkbox"
                            name={`provider_id_${provider.provider_id}`}
                            id={`provider_id_${provider.provider_id}`}
                            onChange={handleProviderChange.bind(null, provider.provider_id)}
                            checked={form.provider_ids.indexOf(provider.provider_id) >= 0}
                          />
                          <label htmlFor={`provider_id_${provider.provider_id}`}>{provider.provider_name}</label>
                        </div>
                      ))}
                  </div>
                </div>
              </ContentCard>
            </div>
          </div>
        </div>

        <div className="island--smaller--block-end">
          <div className="gw">
            <div className="g g--1-of-1">
              <ContentCard>
                <div className="form-block form-block--no-mar">
                  <div className="form-title">Channel Breaks</div>
                  <div className="form-field form-field--rows">
                    {breaks.map((cBreak, key) => (
                      <div className="provider-checkbox" key={key}>
                        <input
                          className="form-checkbox"
                          type="checkbox"
                          checked={cBreak.checked}
                          onChange={toggleChannelBreak.bind(null, key)}
                          id={`channel_break_${cBreak.start}`}
                          disabled={cBreak.disabled}
                        />
                        <label htmlFor={`channel_break_${cBreak.start}`}>{cBreak.start}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </ContentCard>
            </div>
          </div>
        </div>

        <div className="form-title">Playout Configurations</div>

        {form.playouts
          ? form.playouts.map((playout, index) => (
              <div className="island--smaller--block-end" key={index}>
                <div className="gw">
                  <div className="g g--1-of-1">
                    <ContentCard>
                      <div className="form-block">
                        <div className="form-section">
                          <div className="form-section__label">
                            {playout.channel_playout_id
                              ? `${
                                  playout.destination_reference
                                } (${playout.destination_output?.toUpperCase()} - ${playout.destination})`
                              : `New playout (${index + 1})`}
                          </div>

                          <div className="form-field">
                            <label>Integration</label>
                            <input
                              type="text"
                              name="destination"
                              readOnly={!canEditField(playout, "destination")}
                              disabled={!canEditField(playout, "destination")}
                              onChange={handlePlayoutFieldChange.bind(null, index, "destination")}
                              defaultValue={action === "edit" ? playout.destination : "media-tailor"}
                            />
                            {doesPlayoutHaveError(index, "destination") ? (
                              <div className="form-field__error">{getPlayoutError(index, "destination")}</div>
                            ) : null}
                          </div>

                          <div className="form-field">
                            <label htmlFor="">Output Type</label>
                            <div className="radio-group">
                              <input
                                className="form-radio"
                                type="radio"
                                name={`destination_output_${index}`}
                                id={`destination_output_hls_${index}`}
                                onChange={handlePlayoutFieldChange.bind(null, index, "destination_output")}
                                checked={form.playouts[index].destination_output === "hls"}
                                value="hls"
                              />
                              <label htmlFor={`destination_output_hls_${index}`}>HLS</label>
                            </div>
                            <div className="radio-group">
                              <input
                                className="form-radio"
                                type="radio"
                                name={`destination_output_${index}`}
                                id={`destination_output_dash_${index}`}
                                onChange={handlePlayoutFieldChange.bind(null, index, "destination_output")}
                                checked={form.playouts[index].destination_output === "dash"}
                                value="dash"
                              />
                              <label htmlFor={`destination_output_dash_${index}`}>Dash</label>
                            </div>
                            {doesPlayoutHaveError(index, "destination_output") ? (
                              <div className="form-field__error">{getPlayoutError(index, "destination_output")}</div>
                            ) : null}
                          </div>

                          <div className="form-field">
                            <label>Channel Name</label>
                            <input
                              type="text"
                              name="destination_reference"
                              readOnly={!canEditField(playout, "destination_reference")}
                              disabled={!canEditField(playout, "destination_reference")}
                              onChange={handlePlayoutFieldChange.bind(null, index, "destination_reference")}
                              defaultValue={action === "edit" ? playout.destination_reference : ""}
                            />
                            {doesPlayoutHaveError(index, "destination_reference") ? (
                              <div className="form-field__error">{getPlayoutError(index, "destination_reference")}</div>
                            ) : null}
                          </div>
                          {/* default_slate_reference */}
                          <div className="form-field">
                            <label>Default Slate</label>
                            <input
                              type="text"
                              name="default_slate_reference"
                              readOnly={!canEditField(playout, "default_slate_reference")}
                              disabled={!canEditField(playout, "default_slate_reference")}
                              onChange={handlePlayoutFieldChange.bind(null, index, "default_slate_reference")}
                              defaultValue={action === "edit" ? playout.default_slate_reference : ""}
                            />
                            {doesPlayoutHaveError(index, "default_slate_reference") ? (
                              <div className="form-field__error">
                                {getPlayoutError(index, "default_slate_reference")}
                              </div>
                            ) : null}
                          </div>
                          {/* destination_source_location */}
                          <div className="form-field">
                            <label>Source Location</label>
                            <input
                              type="text"
                              name="default_source_location"
                              readOnly={!canEditField(playout, "destination_source_location")}
                              disabled={!canEditField(playout, "destination_source_location")}
                              onChange={handlePlayoutFieldChange.bind(null, index, "destination_source_location")}
                              defaultValue={action === "edit" ? playout.destination_source_location : ""}
                            />
                            {doesPlayoutHaveError(index, "destination_source_location") ? (
                              <div className="form-field__error">
                                {getPlayoutError(index, "destination_source_location")}
                              </div>
                            ) : null}
                          </div>
                          {/* manifest_override */}
                          <div className="form-field">
                            <label>Manifest File Override (leave blank if unsure)</label>
                            <input
                              type="text"
                              name="manifest_override"
                              readOnly={!canEditField(playout, "manifest_override")}
                              disabled={!canEditField(playout, "manifest_override")}
                              onChange={handlePlayoutFieldChange.bind(null, index, "manifest_override")}
                              defaultValue={action === "edit" ? playout.manifest_override : ""}
                            />
                            {doesPlayoutHaveError(index, "manifest_override") ? (
                              <div className="form-field__error">{getPlayoutError(index, "manifest_override")}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {playout.distributions
                        ? playout.distributions.map((distribution, distributionIndex) => (
                            <ChannelFormDistribution
                              key={distributionIndex}
                              distribution={distribution}
                              index={distributionIndex}
                              playoutIndex={index}
                              changeDistribution={handleDistributionChange}
                              distributionTypes={distributionTypes}
                              ssaiTypes={ssaiTypes}
                              errors={
                                errors.playouts?.length ? errors.playouts[index]?.distributions[distributionIndex] : {}
                              }
                              action={action}
                              removeDistribution={(distributionIndex) =>
                                removePlayoutDistribution(index, distributionIndex)
                              }
                            />
                          ))
                        : null}
                      <div className="form-field">
                        <button
                          className="btn btn--inverse-primary"
                          type="button"
                          onClick={() => addPlayoutDistribution(index)}
                        >
                          Add Distribution
                        </button>
                      </div>
                    </ContentCard>
                  </div>
                </div>
              </div>
            ))
          : null}
        <div className="form-field">
          <button className="btn btn--inverse-primary" type="button" onClick={addPlayout}>
            Add Playout
          </button>
        </div>
        {errors.hasOwnProperty("api") ? <p className="u-fc--warning">{errors.api}</p> : null}
      </form>
    </React.Fragment>
  );
}

export default ChannelForm;
