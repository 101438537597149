import React from "react";
import { Droppable } from "react-beautiful-dnd";
import DraggableContentListItem from "./draggable-content-list-item.jsx";
import { getKeyForUnknownContent } from "../utils/accessors.js";
import ContentListFilters from "../../../components/content-list/content-list-filters.jsx";
import ContentListPagination from "../../../components/content-list/content-list-pagination.jsx";
import ContentListResults from "../../../components/content-list/content-list-results.jsx";
import ContentList from "../../../components/content-list/content-list.jsx";
import { getTypeForContent } from "../utils/accessors.js";
import SeriesItem from "../../../components/content-list/series-item.jsx";
import CancelRounded from "@mui/icons-material/CancelRounded";

function DraggableContentList({
  programs,
  getPrograms,
  droppableId,
  channel,
  isLoading,
  addProgram,
  setActiveProgram,
  setAlternateStreamAssetId,
  onContentDrag,
  transcodingTypes = [],
  blockFiltering = { active: false },
  clearAppliedFilters = () => {},
}) {
  const [pageSize, setPageSize] = React.useState(50); // 50 items per page by default
  const [series, setSeries] = React.useState({});

  const resetSeries = React.useCallback(() => {
    setSeries({});
  }, []);

  return (
    <ContentList>
      {!blockFiltering.active ? (
        <ContentListFilters
          providers={channel.providers}
          getPrograms={getPrograms}
          overridePagesize={pageSize}
          showInactiveContent={false}
          series={series}
          resetSeries={resetSeries}
          forChannelId={channel.channel_id}
        />
      ) : (
        <div className="content-list__clear-filters">
          <span>{blockFiltering.blockName}</span>
          <button
            className="btn btn--icon"
            onClick={() => clearAppliedFilters()}
            aria-label="Clear filter"
            data-balloon-pos="left"
          >
            <CancelRounded />
          </button>
        </div>
      )}
      <ContentListResults isLoading={isLoading} results={programs?.data}>
        {(results) => (
          <Droppable droppableId={droppableId} direction="vertical" isDropDisabled={true}>
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef} className="scheduler-content__list">
                {getTypeForContent(results[0]) === "series"
                  ? results.map((item, index) => (
                      <li
                        className="content-list__item content-list__item--clickable"
                        onClick={() => setSeries({ value: item.series_guid, label: item.series_name })}
                        key={`content-list__item__${index}`}
                      >
                        <SeriesItem series={item} getEpisodesForSeries={setSeries} actions={[]} />
                      </li>
                    ))
                  : results.map((item, index) => (
                      <DraggableContentListItem
                        program={item}
                        index={index}
                        key={item[getKeyForUnknownContent(item, "guid")]}
                        addProgram={addProgram}
                        selectProgram={setActiveProgram}
                        onPlay={setAlternateStreamAssetId}
                        transcodingTypes={transcodingTypes}
                        onActions={{
                          getEpisodesForSeries: setSeries,
                        }}
                        onContentDrag={onContentDrag}
                        blockFiltering={blockFiltering}
                      />
                    ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        )}
      </ContentListResults>
      <ContentListPagination
        getPrograms={getPrograms}
        prevUrl={programs?.prev_page_url}
        nextUrl={programs?.next_page_url}
        size={pageSize}
        setSize={setPageSize}
      />
    </ContentList>
  );
}

export default DraggableContentList;
