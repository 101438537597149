import React from "react";
import ReactModal from "react-modal";
import Select from "../../../common/select.jsx";
import { dateToHourMinutesSeconds } from "../../../common/duration-formatting.js";
import { formatDuration, intervalToDuration, differenceInSeconds } from "date-fns";

function CopySegmentDialog({ isOpen, onClose, onValidationPass, segments, referenceIndex }) {
  const [segmentOptions, setSegmentOptions] = React.useState([]);
  const [sourceSegmentIndex, setSourceSegmentIndex] = React.useState(-1);
  const [targetSegmentIndex, setTargetSegmentIndex] = React.useState(0);
  const [isValid, setIsValid] = React.useState(true);
  const [validationError, setValidationError] = React.useState("");

  React.useEffect(() => {
    segmentsToDropdownOptions(segments);
    setSourceSegmentIndex(referenceIndex);
    setTargetSegmentIndex(referenceIndex + 1);
  }, [segments, referenceIndex]);

  function segmentsToDropdownOptions(segments) {
    if (segments.length === 0) {
      return;
    }

    const options = segments.map((segment, index) => {
      return {
        value: index,
        label: `${dateToHourMinutesSeconds(segment.start)} - ${dateToHourMinutesSeconds(segment.end)}`,
      };
    });

    setSegmentOptions(options);
  }

  function getContentLengthForSegment(segment) {
    if (!segment.items) {
      return 0;
    }

    const programs = segment.items;

    let totalDuration = 0;
    programs.forEach((program) => {
      totalDuration += program.__gstvMeta.total_duration_seconds;
    });

    return totalDuration; // returns length of content in seconds
  }

  function validateCopy(sourceIndex, targetIndex) {
    // Fail if source segment length has been exceeded
    if (segmentLengthExceeded(segments[sourceIndex])) {
      setValidationError("Content in segment to copy from must fit in its segment.");
      setIsValid(false);
      return;
    }

    // Fail if the length of the content in the source segment is longer than the size of the target segment
    if (
      getContentLengthForSegment(segments[sourceIndex]) >
      differenceInSeconds(segments[targetIndex].end, segments[targetIndex].start)
    ) {
      setValidationError("To segment is not long enough for all of the content.");
      setIsValid(false);
      return;
    }

    // if okay, do the copy
    setIsValid(true);
    onValidationPass(sourceIndex, targetIndex);
  }

  function segmentLengthExceeded(segment) {
    if (segment.items.length === 0) {
      return false;
    }

    const lastProgramEnd = segment.items[segment.items.length - 1].till;

    return lastProgramEnd > segment.end;
  }

  function onSourceContentChange(option) {
    setSourceSegmentIndex(option.value);
  }

  function onTargetContentChange(option) {
    setTargetSegmentIndex(option.value);
  }

  function durationFormatter(duration) {
    return formatDuration(intervalToDuration({ start: 0, end: duration * 1000 }));
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-copy">
        <div className="v-react-modal__heading">Copy Channel Plan Segment</div>
        <div className="v-react-modal__body modal-copy__body">
          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Copy from:</div>
            <div className="modal-copy__dropdown">
              <Select
                value={
                  segmentOptions.length > 0
                    ? segmentOptions[sourceSegmentIndex]
                    : { value: "Select", label: "Select a segment" }
                }
                name={"copySource"}
                options={segmentOptions}
                onChange={(input) => onSourceContentChange(input)}
                classNamePrefix="react-select"
                isSearchable={false}
              />
            </div>
          </div>
          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Paste to:</div>
            <div className="modal-copy__dropdown">
              <Select
                value={
                  segmentOptions.length > 0
                    ? segmentOptions[targetSegmentIndex]
                    : { value: "Select", label: "Select a segment" }
                }
                name={"copyTarget"}
                options={segmentOptions}
                onChange={(input) => onTargetContentChange(input)}
                classNamePrefix="react-select"
                isSearchable={false}
              />
            </div>
          </div>
          <div className="modal-copy__body__subtext">
            Source segment duration:
            {segments[sourceSegmentIndex]
              ? " " + durationFormatter(getContentLengthForSegment(segments[sourceSegmentIndex]))
              : "0"}
          </div>
          <div>
            Duration of target segment:
            {segments[targetSegmentIndex]
              ? durationFormatter(
                  differenceInSeconds(segments[targetSegmentIndex].end, segments[targetSegmentIndex].start),
                )
              : "0"}
          </div>
          <div className={"modal-copy__body__subtext modal-copy__body__subtext--error"}>
            {!isValid ? validationError : ""}
          </div>
        </div>
        <div className="v-react-modal__footer" style={{ marginTop: "4px" }}>
          <button className="btn btn--inverse-primary" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn--primary" onClick={() => validateCopy(sourceSegmentIndex, targetSegmentIndex)}>
            Copy to segment
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default CopySegmentDialog;
