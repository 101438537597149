import React from "react";
import AutoAwesomeMotionRoundedIcon from "@mui/icons-material/AutoAwesomeMotionRounded";

function SeriesItem({ series, getEpisodesForSeries, actions, controlComponent }) {
  return (
    <span className="content-item">
      {typeof controlComponent === "function" ? controlComponent() : null}
      <span className="content-item__description">
        <span className="content-item__heading">{series.formatted_name}</span>
        <span className="content-item__provider">
          <span className="content-item__provider__inner">{series.title.provider.provider_name}</span>
        </span>
        <span className="content-item__sub-heading">
          <span>Total Seasons: {series?.seasons.length}</span>
        </span>
        <br />
      </span>
      <span className="content-item__actions">
        {actions.map((action, key) => (
          <span className="content-item__action" key={key}>
            {action}
          </span>
        ))}
        <span className="content-item__action">
          <button
            className="btn--text-primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              getEpisodesForSeries({ value: series.series_guid, label: series.formatted_name });
            }}
          >
            <AutoAwesomeMotionRoundedIcon />
          </button>
        </span>
      </span>
    </span>
  );
}

export default SeriesItem;
