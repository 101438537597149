import { differenceInSeconds } from "date-fns";
import dateIsBetween from "../../../common/date-is-between";
import { secondsToHourMinutesSeconds } from "../../../common/duration-formatting";
import { getHeightStyleFromSeconds } from "./style-helpers";

export function getHeightFromSeconds(secondsPerSection, pixelsPerSection, seconds) {
  const sections = seconds / secondsPerSection;
  const pixels = sections * pixelsPerSection;

  return pixels;
}

function findFurthestShortProgram(
  fromIndex,
  list,
  shortHeight,
  secondsPerSection,
  pixelsPerSection,
  direction,
  distance = 0,
) {
  if (!list[fromIndex]) {
    return distance;
  }

  const current = getHeightFromSeconds(
    secondsPerSection,
    pixelsPerSection,
    list[fromIndex].__gstvMeta.total_duration_seconds,
  );

  if (current > shortHeight) {
    return distance;
  }

  return findFurthestShortProgram(
    fromIndex + direction,
    list,
    shortHeight,
    secondsPerSection,
    pixelsPerSection,
    direction,
    distance + 1,
  );
}

// offset can be [-1, y], [0, y], or [1, y]
export function getSmallOffset(fromIndex, list, shortHeight, secondsPerSection, pixelsPerSection) {
  const shortProgramsBehind =
    findFurthestShortProgram(fromIndex, list, shortHeight, secondsPerSection, pixelsPerSection, -1) - 1;
  const shortProgramsAhead =
    findFurthestShortProgram(fromIndex, list, shortHeight, secondsPerSection, pixelsPerSection, 1) - 1;

  let x = shortProgramsBehind !== 0 ? (shortProgramsBehind % 3) - 1 : 0;
  const y = shortProgramsBehind !== 0 ? Math.floor(shortProgramsBehind / 3) : 0;

  if (shortProgramsAhead > 0 && shortProgramsBehind === 0 && x === 0) {
    x -= 1;
  }

  return [x, y];
}

export function getPrependedMargin(fromIndex, list, shortHeight, secondsPerSection, pixelsPerSection) {
  const indexHeight = getHeightFromSeconds(
    secondsPerSection,
    pixelsPerSection,
    list[fromIndex].__gstvMeta.total_duration_seconds,
  );
  if (indexHeight <= shortHeight) {
    return 0;
  }

  const shortProgramsBehind = findFurthestShortProgram(
    fromIndex - 1,
    list,
    shortHeight,
    secondsPerSection,
    pixelsPerSection,
    -1,
  );

  let marginAdjustment = 0;

  if (shortProgramsBehind > 0) {
    for (let i = 1; i < shortProgramsBehind + 1; i++) {
      const current = getHeightFromSeconds(
        secondsPerSection,
        pixelsPerSection,
        list[fromIndex - i].__gstvMeta.total_duration_seconds,
      );

      marginAdjustment += current;
    }
  }

  return marginAdjustment;
}

export function getMarkerPopOutWidth(calendarDivRef) {
  if (calendarDivRef.current) {
    return `${calendarDivRef.current.clientWidth}px`;
  }

  return "600px";
}

export function getTimeRemainingInSegment(segment, epg) {
  let remaining = 0;
  if (segment.items.length) {
    remaining = differenceInSeconds(segment.end, segment.items[segment.items.length - 1].till);
  } else {
    // check if there are shows that end in this segment
    const candidates = epg.filter((program) => {
      return dateIsBetween(program.till, segment.start, segment.end);
    });

    if (candidates.length) {
      remaining = differenceInSeconds(segment.end, candidates[candidates.length - 1].till);
    } else {
      remaining = differenceInSeconds(segment.end, segment.start);
    }
  }

  if (remaining <= 0) {
    return "";
  }
  return secondsToHourMinutesSeconds(remaining);
}

export function getSchedulerSegmentStyle(segments, sIndex, secondsPerSection, pixelsPerSection) {
  const segment = segments[sIndex];

  if (!segment) {
    return;
  }

  return {
    height: getHeightStyleFromSeconds(
      differenceInSeconds(segment.end, segment.start),
      secondsPerSection,
      pixelsPerSection,
    ),
    ...(sIndex > 0 && getLastProgramForSegment(sIndex - 1, segments)?.till > segments[sIndex - 1].end
      ? {
          paddingTop: `${getProgramVerticalOffset(segments[sIndex - 1], secondsPerSection, pixelsPerSection)}`,
        }
      : {}),
  };
}

export function getProgramVerticalOffset(segment, secondsPerSection, pixelsPerSection) {
  return getHeightStyleFromSeconds(
    (segment.items[segment.items.length - 1].till - segment.end) / 1000,
    secondsPerSection,
    pixelsPerSection,
  );
}

export function getLastProgramForSegment(sIndex, segments) {
  return segments[sIndex].items[segments[sIndex].items.length - 1];
}
