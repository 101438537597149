import { addMinutes, addSeconds, differenceInSeconds } from "date-fns";
import { v4 } from "uuid";
import { dateToHourMinutesSeconds } from "../../../common/duration-formatting";

const MODELS_WITH_CUEPOINTS = ["feature", "episode"];

const NEW_BLOCK_INITIAL_DURATION_MINUTES = 30;

function fromUtcDate(utcDate) {
  let offsetDate = new Date(utcDate);
  const modifier = offsetDate < 0 ? -1 : 1;
  offsetDate = addMinutes(offsetDate, offsetDate.getTimezoneOffset() * modifier);
  return offsetDate;
}

export function fromApiToTemplateItem(item) {
  let type = "feature";

  if (item.link.feature_id) {
    type = "feature";
  } else if (item.link.episode_id) {
    type = "episode";
  } else if (item.link.promo_id) {
    type = "promo";
  } else if (item.link.series_id) {
    type = "series";
  } else if (item.link.template_block_id) {
    type = "template_block";
  }

  const adBreaks = MODELS_WITH_CUEPOINTS.includes(type)
    ? item.link.cuepoints.map((contentCuepoint) => ({
        ad_break: contentCuepoint.ad_break,
        cuepoint: contentCuepoint.cuepoint,
      }))
    : [];

  let templateItem = {
    template_day_item_id: item.template_day_item_id,
    template_day_id: item.template_day_id,

    drag_id: v4(),
    id: item.link[`${type}_guid`],
    title: item.link.formatted_name || item.link.series_name || item.link.block_label,
    total_duration_seconds: item.duration,
    original_total_duration_seconds: item.link.total_duration || item.duration,
    type: item.link_type,
    __gstvMeta: {
      ad_breaks: adBreaks,
      duration_seconds: Math.floor(item.link.trimmed_duration ?? item.duration),
      total_duration_seconds: item.duration,
      original_total_duration_seconds: item.link.total_duration || item.duration,
    },

    since: fromUtcDate(item.starts_at),
    till: fromUtcDate(item.ends_at),
  };

  if (item.link_type === "template_block") {
    let filters = null;
    let copyFrom = null;
    let blockType = item.link.block_type;

    if (blockType === "filter") {
      filters = definitionsToFilters(item.link.definitions);
    }

    if (blockType === "segment") {
      blockType = "copy";
      copyFrom = new Date(item.link.definitions[0].definition_value);
    }

    templateItem = {
      ...templateItem,
      id: item.template_day_item_id,
      template_block_id: item.link.template_block_id,
      blockType,
      filters,
      copyFrom,
    };
  }

  return templateItem;
}

export function fromContentListToTemplateItem(contentItem) {
  let type = "feature";

  if (contentItem.feature_id) {
    type = "feature";
  } else if (contentItem.episode_id) {
    type = "episode";
  } else if (contentItem.promo_id) {
    type = "promo";
  } else if (contentItem.series_id) {
    type = "series";
  }

  const adBreaks = MODELS_WITH_CUEPOINTS.includes(type)
    ? contentItem.cuepoints.map((contentCuepoint) => ({
        ad_break: contentCuepoint.ad_break,
        cuepoint: contentCuepoint.cuepoint,
      }))
    : [];

  const item = {
    drag_id: v4(),
    id: contentItem[`${type}_guid`],
    title: contentItem.formatted_name || contentItem.series_name,
    total_duration_seconds: contentItem.total_duration,
    original_total_duration_seconds: contentItem.total_duration,
    type: type,
    __gstvMeta: {
      ad_breaks: adBreaks,
      duration_seconds: Math.floor(contentItem.trimmed_duration ?? contentItem.duration),
      total_duration_seconds: contentItem.total_duration,
      original_total_duration_seconds: contentItem.total_duration,
    },

    since: null,
    till: null,
  };

  return item;
}

export function fromBlockDataToTemplateItem(blockData) {
  const sourceDuration = differenceInSeconds(blockData.end, blockData.start);
  let title = blockData.blockName;
  if (!title && blockData.type === "copy") {
    title = `Block: Duplicate of segment ${dateToHourMinutesSeconds(blockData.sourceSegment.start)} - ${dateToHourMinutesSeconds(
      blockData.sourceSegment.end,
    )}`;
  } else if (!title && blockData.type === "filter") {
    title = filtersToBlockName(blockData.filters);
  }

  let duration = NEW_BLOCK_INITIAL_DURATION_MINUTES * 60;
  let maximumDuration = 24 * 60 * 60;
  let end = blockData.end;
  if (blockData.type === "copy") {
    duration = sourceDuration;
    maximumDuration = sourceDuration;
    end = addSeconds(
      new Date(blockData.start),
      differenceInSeconds(blockData.sourceSegment.end, blockData.sourceSegment.start),
    );
  }

  if (blockData.type === "filter") {
    end = addMinutes(new Date(blockData.start), NEW_BLOCK_INITIAL_DURATION_MINUTES);
  }

  return {
    title,
    sourceSegment: blockData.sourceSegment,
    copyFrom: blockData.copyFrom,
    sourceIndex: blockData.sourceIndex,
    filters: blockData.filters,
    total_duration_seconds: duration, // @todo maybe remove this
    original_total_duration_seconds: maximumDuration, // @todo maybe remove this
    since: blockData.start,
    till: end,
    drag_id: v4(),
    id: v4(),
    type: "template_block",
    blockType: blockData.type,
    __gstvMeta: {
      ad_breaks: [],
      duration_seconds: duration,
      total_duration_seconds: duration,
      original_total_duration_seconds: maximumDuration,
    },
  };
}

export function filtersToDefinitions(filterData) {
  let processedFilters = [];

  Object.keys(filterData).forEach((filterKey) => {
    let filterValue = filterData[filterKey];

    switch (filterKey) {
      case "search":
        if (!filterValue) {
          break;
        }
        processedFilters.push({
          definition_type: "text",
          definition_value: filterValue,
        });
        break;

      case "providers":
        if (filterValue.length) {
          filterValue.forEach((provider) => {
            processedFilters.push({
              definition_type: "link",
              link_type: "provider",
              link_id: provider.value ?? provider.provider_id,
            });
          });
        }

        break;

      case "contentType":
        processedFilters.push({
          definition_type: "content_type",
          definition_value:
            filterValue.value !== "series"
              ? filterValue.value.substring(0, filterValue.value.length - 1)
              : filterValue.value,
        });
        break;

      case "ratings":
        if (filterValue.length) {
          filterValue.forEach((filterRating) => {
            const schemeParts = filterRating.value.split("_");
            const rated = schemeParts.pop();
            const scheme = schemeParts.join("_");

            processedFilters.push({
              definition_type: "link",
              link_type: `rating_${scheme}`,
              link_id: rated,
            });
          });
        }
        break;

      case "genres":
        if (filterValue.length) {
          filterValue.forEach((filterGenre) => {
            processedFilters.push({
              definition_type: "link",
              link_type: "genre_imdb",
              link_id: filterGenre.id ?? filterGenre.value,
            });
          });
        }
        break;

      case "subgenres":
        if (filterValue.length) {
          filterValue.forEach((filterSubgenre) => {
            processedFilters.push({
              definition_type: "link",
              link_type: "subgenre_imdb",
              link_id: filterSubgenre.id ?? filterSubgenre.value,
            });
          });
        }
        break;

      case "durationMin":
        if (!filterValue) {
          break;
        }
        processedFilters.push({
          definition_type: "duration_min",
          definition_value: filterValue * 60,
        });
        break;

      case "durationMax":
        if (!filterValue) {
          break;
        }
        processedFilters.push({
          definition_type: "duration_max",
          definition_value: filterValue * 60,
        });
        break;

      case "series":
        if (!Object.keys(filterValue).length) {
          break;
        }
        processedFilters.push({
          definition_type: "link",
          link_type: "series",
          link_id: filterValue.value,
        });
        break;

      case "season":
        if (!Object.keys(filterValue).length) {
          break;
        }
        processedFilters.push({
          definition_type: "link",
          link_type: "season",
          link_id: filterValue.value,
        });
        break;
    }
  });

  return processedFilters;
}

function definitionsToFilters(definitions) {
  let newFilters = {
    search: "",
    providers: [],
    contentType: "",
    ratings: [],
    genres: [],
    subgenres: [],
    durationMin: "",
    durationMax: "",
    series: {},
    season: {},
  };

  definitions.forEach((definition) => {
    switch (definition.definition_type) {
      case "text":
        newFilters.search = definition.definition_value;
        break;

      case "content_type": {
        // make sure it's plural form
        const value =
          definition.definition_value === "series" ? definition.definition_value : `${definition.definition_value}s`;
        const label = value.charAt(0).toUpperCase() + value.slice(1);

        newFilters.contentType = { value, label };
        break;
      }

      case "duration_min":
        newFilters.durationMin = definition.definition_value;
        break;

      case "duration_max":
        newFilters.durationMax = definition.definition_value;
        break;

      case "link":
        switch (definition.link_type) {
          case "provider":
            newFilters.providers.push({ provider_id: definition.link_id });
            break;

          case "genre_imdb":
            newFilters.genres.push({ label: "", value: definition.link_id });
            break;

          case "subgenre_imdb":
            newFilters.subgenres.push({ label: "", value: definition.link_id });
            break;
          case "rating_mpaa":
          case "rating_bbfc":
          case "rating_us_tv": {
            let pieces = definition.link_type.split("_");
            pieces.shift();
            pieces = pieces.join("_");
            newFilters.ratings.push({ label: "", value: `${pieces}_${definition.link_id}` });
            break;
          }

          case "series":
            newFilters.series = { label: "", value: definition.link_id };
            break;

          case "season":
            newFilters.season = { label: "", value: definition.link_id };
            break;
        }

        break;
    }
  });

  return newFilters;
}

function filtersToBlockName(filterData) {
  let processedFilters = [];

  Object.keys(filterData).forEach((filterKey) => {
    let filterValue = filterData[filterKey];

    switch (filterKey) {
      case "search":
        if (!filterValue) {
          break;
        }
        processedFilters.push("Search: " + filterValue);
        break;

      case "durationMin":
        if (!filterValue) {
          break;
        }
        processedFilters.push(`Min Duration: ${filterValue}mins`);
        break;

      case "durationMax":
        if (!filterValue) {
          break;
        }
        processedFilters.push(`Max Duration: ${filterValue}mins`);
        break;

      case "contentType":
        processedFilters.push(
          `Content Type: ${
            filterValue.value !== "series"
              ? filterValue.label.substring(0, filterValue.label.length - 1)
              : filterValue.label
          }`,
        );
        break;

      case "series":
        if (!Object.keys(filterValue).length) {
          break;
        }
        processedFilters.push(`Series: ${filterValue.label}`);
        break;

      case "season":
        if (!Object.keys(filterValue).length) {
          break;
        }
        processedFilters.push(`Season: ${filterValue.label}`);
        break;

      case "providers":
        if (filterValue.length) {
          const providerString = filterValue.map((provider) => provider.label).join(", ");
          processedFilters.push(`Providers: ${providerString}`);
        }
        break;

      case "ratings":
        if (filterValue.length) {
          if (filterValue.length) {
            const ratingString = filterValue.map((rating) => rating.label).join(", ");
            processedFilters.push(`Ratings: ${ratingString}`);
          }
          break;
        }
        break;

      case "genres":
        if (filterValue.length) {
          if (filterValue.length) {
            const genreString = filterValue.map((genre) => genre.label).join(", ");
            processedFilters.push(`Genre: ${genreString}`);
          }
        }
        break;

      case "subgenres":
        if (filterValue.length) {
          if (filterValue.length) {
            const subgenreString = filterValue.map((subgenre) => subgenre.label).join(", ");
            processedFilters.push(`Subgenre: ${subgenreString}`);
          }
        }
        break;
    }
  });

  return `Filter Block: ${processedFilters.join(" | ")}`;
}
