import React from "react";
import ReactModal from "react-modal";
import Select from "../../../common/select.jsx";
import { dateToHourMinutesSeconds } from "../../../common/duration-formatting.js";
import TemplateBlockFilters from "../template-block-filters.jsx";
import { differenceInSeconds } from "date-fns";

const MAXIMUM_BLOCK_DURATION = 24 * 60 * 60; // 8 HOURS IN SECONDS

const contentTypes = [
  {
    value: "features",
    label: "Features",
  },
  {
    value: "episodes",
    label: "Episodes",
  },
  {
    value: "promos",
    label: "Promos",
  },
  {
    value: "series",
    label: "Series",
  },
];
const defaultContentType = contentTypes[0];

export default function EditBlockDialog({
  isOpen,
  onClose,
  dayNumber,
  segments = [],
  providers = [],
  onSubmit,
  templateBlock,
}) {
  const initialState = {
    search: "",
    providers: [],
    contentType: defaultContentType,
    ratings: [],
    genres: [],
    subgenres: [],
    durationMin: "",
    durationMax: "",
    series: {},
    season: {},
  };

  const [blockType, setBlockType] = React.useState(templateBlock?.blockType);
  const [blockName, setBlockName] = React.useState(templateBlock?.title || "");

  const [segmentOptions, setSegmentOptions] = React.useState([]);
  const [sourceSegmentIndex, setSourceSegmentIndex] = React.useState(
    templateBlock?.blockType === "copy" ? templateBlock.sourceIndex : -1,
  );
  const [filters, setFilters] = React.useState(initialState);

  React.useEffect(() => {
    if (templateBlock?.filters) {
      // format the templateBlock filters to match expected format
      const templateBlockFilters = {
        ...templateBlock.filters,
      };

      if (templateBlock.filters.providers) {
        templateBlockFilters.providers = templateBlock.filters.providers.map((provider) => {
          return {
            value: provider.value ?? provider.provider_id,
            label: provider.label ?? null,
          };
        });
      }
      setFilters(templateBlockFilters);
    }
  }, [templateBlock?.filters]);

  React.useEffect(() => {
    segmentsToDropdownOptions(segments);
  }, [segments]);

  function segmentsToDropdownOptions(segments) {
    if (segments.length === 0) {
      return;
    }

    const options = segments.map((segment, index) => {
      return {
        value: index,
        label: `${dateToHourMinutesSeconds(segment.start)} - ${dateToHourMinutesSeconds(segment.end)}`,
      };
    });

    setSegmentOptions(options);
  }

  function setTypeToCopy() {
    if (blockType !== "copy") {
      setFilters(initialState);
      setBlockType("copy");
    }
  }

  function setTypeToFilter() {
    if (blockType !== "filter") {
      setSourceSegmentIndex(-1);
      setBlockType("filter");
    }
  }

  function getUpdatedTemplateBlock() {
    let blockFilters = null;
    let sourceSegment = null;
    let sourceIndex = null;
    let newDuration = null;
    let copyFrom = null;

    if (blockType === "copy") {
      sourceSegment = segments[sourceSegmentIndex];
      sourceIndex = segmentOptions[sourceSegmentIndex].value;
      copyFrom = sourceSegment.start;
      newDuration = differenceInSeconds(sourceSegment.end, sourceSegment.start);
    }

    if (blockType === "filter") {
      blockFilters = { ...filters };
    }

    return {
      ...templateBlock,
      title: blockName,
      blockType: blockType,
      filters: blockFilters,

      total_duration_seconds: newDuration ?? templateBlock.total_duration_seconds,
      original_total_duration_seconds: newDuration ?? MAXIMUM_BLOCK_DURATION,
      __gstvMeta: {
        ...templateBlock.__gstvMeta,
        total_duration_seconds: newDuration ?? templateBlock.__gstvMeta.total_duration_seconds,
        original_total_duration_seconds: newDuration ?? MAXIMUM_BLOCK_DURATION,
      },
      sourceSegment,
      sourceIndex,
      copyFrom,
    };
  }

  function onConfirm() {
    // @TODO add validation on copy to make sure we have space(time) to copy the selected source into the available space
    const blocks = getUpdatedTemplateBlock();
    onSubmit(dayNumber, blocks);
    onClose();
  }

  function onSegmentChange(input) {
    setTypeToCopy();
    setSourceSegmentIndex(input.value);
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-copy">
        <div className="v-react-modal__heading">Edit Template Block</div>
        <div className="v-react-modal__body modal-copy__body">
          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Block name:</div>
            <div className="modal-copy__dropdown">
              <input
                type="text"
                name="blockName"
                id="blockName"
                value={blockName}
                placeholder="If not filled, a default name will be provided"
                onChange={(e) => setBlockName(e.target.value)}
              />
            </div>
          </div>
          <br />
          <hr />
          <div className="modal-copy__dropdown-group" onMouseDown={setTypeToCopy}>
            <div className="modal-copy__body__text">Copy from:</div>
            <div className="modal-copy__dropdown">
              <Select
                value={
                  segmentOptions.length > 0 && sourceSegmentIndex > -1
                    ? segmentOptions[sourceSegmentIndex]
                    : { value: "Select", label: "Select a segment" }
                }
                name={"copySource"}
                options={segmentOptions}
                onChange={onSegmentChange}
                classNamePrefix="react-select"
                isSearchable={false}
              />
            </div>
          </div>
          OR
          <br />
          <TemplateBlockFilters
            filters={filters}
            updateFilters={setFilters}
            providers={providers}
            changeBlockTypeToFilter={setTypeToFilter}
          />
        </div>
        <div className="v-react-modal__footer">
          <button className="btn btn--inverse-primary" onClick={onClose} key="btn-back">
            Cancel
          </button>
          <button className="btn btn--primary" onClick={onConfirm} key="btn-yes">
            Save {blockType === "copy" ? "Segment Copy Block" : "Filter Block"}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
