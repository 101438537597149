import React from "react";
import intervalToDuration from "date-fns/intervalToDuration";

export default function LibraryItemRow({ item }) {
  function formatDuration(interval) {
    const zeroPad = (num, places) => String(num).padStart(places, "0");
    if (!interval) {
      return null;
    }

    const duration = intervalToDuration({ start: 0, end: interval * 1000 });

    return `${zeroPad(duration.hours, 2)}:${zeroPad(duration.minutes, 2)}:${zeroPad(duration.seconds, 2)}`;
  }

  return (
    <span className="gw">
      <span className="g g--2-of-6">
        <span className="library-list__item__title">
          {item.formatted_name}
          {item.restricted_at ? " 🔒" : ""}
        </span>
      </span>
      <span className="g g--3-of-6">
        <span className="gw">
          <span className="g g--1-of-4" data-balloon-pos="up" aria-label="BBFC">
            <span className="u-width-100 u-display--flex-vh-center">{item.rating?.bbfc_rating ?? "N/A"}</span>
          </span>
          <span className="g g--1-of-4" data-balloon-pos="up" aria-label="MPAA">
            <span className="u-width-100 u-display--flex-vh-center">{item.rating?.mpaa_rating ?? "N/A"}</span>
          </span>
          <span className="g g--1-of-4" data-balloon-pos="up" aria-label="US-TV">
            <span className="u-width-100 u-display--flex-vh-center">{item.rating?.us_tv_rating ?? "N/A"}</span>
          </span>
          <span className="g g--1-of-4" data-balloon-pos="up" aria-label="IMDb Genre">
            <span className="u-width-100 u-display--flex-vh-center">
              {item.genre?.imdb?.imdb_genre?.label ?? "N/A"}
            </span>
          </span>
        </span>
      </span>
      <span className="g g--1-of-6">
        <span className="u-flex--end">{formatDuration(item.duration) ?? "-"}</span>
      </span>
      <span className="g g--1-of-1">
        {item.feature_id ? item.title?.provider?.provider_name : null}
        {item.episode_id ? item.season?.series?.title?.provider?.provider_name : null}
        {item.promo_id ? item.provider?.provider_name : null}
        {item.series_id ? item.title?.provider?.provider_name : null}
      </span>
      <span className="g g--1-of-1">{item.summary?.short_summary ?? "Description missing..."}</span>
    </span>
  );
}
