import React from "react";
import { getRatings } from "../../../requests/api-requests.js";
import Select from "../../../common/select.jsx";

let __RATING_CACHE__ = [];

function RatingFilter({ onChange, selectedRatings = [] }) {
  const [ratings, setRatings] = React.useState(__RATING_CACHE__);
  React.useEffect(() => {
    if (!ratings.length) {
      getRatings()
        .then((data) => {
          __RATING_CACHE__ = Object.keys(data).reduce((prev, curr) => {
            return [
              ...prev,
              ...data[curr].map((item) => ({
                value: `${curr}_${item.value}`,
                label: `${curr.toUpperCase()} ${item.label}`,
              })),
            ];
          }, []);
          setRatings(__RATING_CACHE__);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [ratings]);

  // potentially patch the filters

  React.useEffect(() => {
    let didPatch = false;
    const fixedSelectedRatings = selectedRatings.map((rating) => {
      let value = rating.value ?? "";
      let label = rating.label ?? "";
      if (!label && value) {
        label = ratings.find((rat) => rat.value === value)?.label ?? "-";
        if (label !== "-") {
          didPatch = true;
        }
      }
      return {
        label,
        value,
      };
    });

    if (didPatch) {
      onChange(fixedSelectedRatings);
    }
  }, [selectedRatings, onChange, ratings]);

  return (
    <Select
      placeholder={"Ratings"}
      name={"rating"}
      isSearchable={true}
      onChange={onChange}
      options={ratings.filter((option) => !selectedRatings.find((sg) => sg.value === option.value))}
      controlShouldRenderValue={false}
      containerClass="react-select-container--light react-select-container--small"
    />
  );
}

export default RatingFilter;
