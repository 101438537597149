import React from "react";
import SortRoundedIcon from "@mui/icons-material/SortRounded";

export default function getTagsFromFilters(filters) {
  // { label, clickable, value }
  const tags = Object.keys(filters)
    .filter((fi) => {
      if (fi === "search" || fi === "forChannelId") {
        return false;
      }

      return !!filters[fi];
    }) // don't show search
    .map((fi) => {
      switch (fi) {
        case "providers":
          return filters[fi].map((provider) => {
            return {
              label: provider.label,
              value: provider.value,
            };
          });
        case "contentType":
          return {
            label: filters[fi].label,
          };
        case "sortOrder":
          return filters[fi].map((sort) => ({
            label: () => (
              <React.Fragment>
                {sort.value.charAt(0).toUpperCase() + sort.value.slice(1)}
                {sort.order === "desc" ? <SortRoundedIcon /> : <SortRoundedIcon />}
              </React.Fragment>
            ),
            removable: true,
            value: `${fi}.${sort.value}`,
          }));
        case "durationMin":
          if (filters.durationMax) {
            // handle the case of min + max in max case
            return null;
          }
          return {
            label: `> ${filters.durationMin} minutes`,
            removable: true,
            value: fi,
          };
        case "durationMax":
          if (filters.durationMin) {
            return {
              label: `${filters.durationMin} - ${filters.durationMax} minutes`,
              removable: true,
              value: [fi, "durationMin"],
            };
          }
          return {
            label: `< ${filters.durationMax} minutes`,
            removable: true,
            value: fi,
          };
        case "series":
        case "season":
          if (!filters[fi].value) {
            return null;
          }
          return {
            label: filters[fi].label,
            removable: true,
            value: fi,
          };
        case "ratings":
          return filters[fi].map((rating) => ({
            label: rating.label,
            removable: true,
            value: `${fi}.${rating.value}`,
          }));
        case "subgenres":
        case "genres":
          return filters[fi].map((genre) => ({
            label: genre.label,
            removable: true,
            value: `${fi}.${genre.value}`,
          }));
        default:
          return {
            label: filters[fi],
            removable: true,
            value: `${fi}.${filters[fi]}`,
          };
      }
    })
    .flat()
    .filter((i) => i && i.label);
  return tags;
}
