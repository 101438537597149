import React from "react";
import useApiRequest from "../../../hooks/use-api-request.js";
import Loader from "../../../common/loader.jsx";
import ContentCard from "../components/content-card.jsx";
import { getSecondsFromHis } from "../../epg-editor/utils/create-ad-breaks.js";
import CuepointMeta from "../components/cuepoint-meta.jsx";
import { secondsToHourMinutesSeconds } from "../../../common/duration-formatting.js";
import Select from "../../../common/select.jsx";
import axios from "../../../requests/axios.js";
import DialogForm from "../../../components/dialog-form.jsx";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import NoEncryptionGmailerrorredRoundedIcon from "@mui/icons-material/NoEncryptionGmailerrorredRounded";
import ChangeVideoSourceDialog from "../../components/dialogs/change-video-source-dialog.jsx";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import useSpreadState from "../../../hooks/use-spread-state.js";
import { AppHeadingActions } from "../../../layout/parts/app-heading.jsx";
import { toast } from "react-toastify";
import GenericDeleteDialog from "../../components/dialogs/generic-delete-dialog.jsx";
import QueueTranscodeDialog from "../../components/queue-transcode-dialog.jsx";
import TranscodingStatusIcon from "../../components/transcoding-status-icon.jsx";
import GenericRestoreDialog from "../../components/dialogs/generic-restore-dialog.jsx";
import { useNavigate } from "react-router-dom";
import ContentPlayer from "../../../components/player/content-player.jsx";
import { areProgramCuepointsSpaced } from "../../epg-editor/utils/cuepoint-spacing-validator.js";
import { formatCuepoints } from "../../../common/cuepoints.js";

let RATING_OPTIONS = {
  mpaa: [],
  us_tv: [],
  bbfc: [],
};

let GENRE_OPTIONS = {
  imdb: [],
  freeview: [],
};

export default function EpisodeTab({ guid }) {
  const [showCastCrewDialog, setShowCastCrew] = React.useState(false);
  const [isChangeSourceOpen, setChangeSourceOpen] = React.useState(false);
  const [isEditing, setEditing] = React.useState(false);
  const [isSaving, setSaving] = React.useState(false);
  const [isTrashing, setTrashing] = React.useState(false);
  const [isRestoring, setRestoring] = React.useState(false);
  const [transcodingDialog, setTranscodingDialog] = React.useState({ isOpen: false, type: "" });

  const [form, updateForm, hardUpdateForm] = useSpreadState({});
  const { isLoading, response, invalidateCache } = useApiRequest(`/api/episodes/${guid}/summary`, { cache: true });
  const [episode, setEpisode] = React.useState({});
  const [data, setData] = React.useState({});
  const [isSwitchingPlayers, setSwitching] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (response.data) {
      setEpisode(response.data);
    }
  }, [response]);

  React.useEffect(() => {
    if (episode) {
      setData({ ...episode, ...(isEditing ? form : {}) });
    }
  }, [form, episode, isEditing]);

  const getActiveRating = React.useCallback(
    (scheme) => {
      if (!data.rating) {
        return null;
      }

      const rating = RATING_OPTIONS[scheme].filter((i) => i.value === data.rating[`${scheme}_rating_id`]);
      if (!rating.length) {
        return null;
      }

      return rating[0];
    },
    [data.rating],
  );

  const getActiveGenre = React.useCallback(
    (scheme) => {
      let genre = null;
      switch (scheme) {
        case "imdb_genre":
          genre = GENRE_OPTIONS["imdb"].find((i) => i.value === data.imdb_genre?.value);
          break;
        case "imdb_subgenre":
          var primaryGenre = getActiveGenre("imdb_genre");
          //  subgenre is linked to a primary genre
          if (!primaryGenre || !data.imdb_subgenre) {
            break;
          }

          genre = getActiveGenre("imdb_genre").subgenres.find((i) => i.value === data.imdb_subgenre?.value);
          break;
        case "freeview_genre":
          genre = GENRE_OPTIONS["freeview"].find((i) => i.value === data.freeview_genre?.value);
          break;
      }

      if (!genre) {
        return null;
      }

      return genre;
    },
    [data.imdb_genre, data.imdb_subgenre, data.freeview_genre],
  );

  const resetForm = React.useCallback(() => {
    hardUpdateForm({
      imdb_genre: episode?.genre?.imdb?.imdb_genre
        ? { label: episode.genre.imdb.imdb_genre.label, value: episode.genre.imdb.imdb_genre.value }
        : null,
      imdb_subgenre: episode?.genre?.imdb?.imdb_subgenre
        ? { label: episode.genre.imdb.imdb_subgenre.label, value: episode.genre.imdb.imdb_subgenre.value }
        : null,
      freeview_genre: episode?.genre?.freeview?.freeview_genre
        ? {
            label: episode.genre.freeview.freeview_genre.label,
            value: episode.genre.freeview.freeview_genre.value,
          }
        : null,
      summary: {
        long_summary: episode.summary?.long_summary ?? "",
        short_summary: episode.summary?.short_summary ?? "",
      },
    });
  }, [episode, hardUpdateForm]);

  const toggleEditMode = React.useCallback(() => {
    setSwitching(true);
    setEditing((prev) => !prev);
    setTimeout(() => {
      setSwitching(false);
    }, 200);
  }, []);

  const cancelEditing = React.useCallback(() => {
    toggleEditMode();
    resetForm();
  }, [resetForm, toggleEditMode]);

  const addCuepoint = React.useCallback(
    (_atSeconds) => {
      const atSeconds = parseInt(_atSeconds, 10);

      updateForm((prev) => {
        const oldAdBreaks = prev.cuepoints ? [...prev.cuepoints] : [];
        const cuepoints = [
          ...oldAdBreaks,
          {
            cuepoint: secondsToHourMinutesSeconds(atSeconds),
            ad_break: secondsToHourMinutesSeconds(122),
          },
        ];

        return {
          ...prev,
          cuepoints,
        };
      });
    },
    [updateForm],
  );

  const removeNearestCuepoint = React.useCallback(
    (atSeconds) => {
      let nearestIndex = 0;
      let nearestTimeInSeconds = 9999999;

      updateForm((prev) => {
        if (!prev.cuepoints?.length) {
          return prev;
        }

        const cuepoints = prev.cuepoints
          .map((ad, index) => {
            const diff = Math.abs(atSeconds - getSecondsFromHis(ad.cuepoint));
            if (diff < nearestTimeInSeconds) {
              nearestTimeInSeconds = diff;
              nearestIndex = index;
            }
            return ad;
          })
          .filter((item, index) => index !== nearestIndex);

        return {
          ...prev,
          cuepoints,
        };
      });
    },
    [updateForm],
  );

  const trimVideo = React.useCallback(
    (_atPlayoutSeconds) => {
      let atPlayoutSeconds = parseInt(_atPlayoutSeconds, 10);
      // reset trim by snipping at end or start of timeline
      if (atPlayoutSeconds > episode.duration || atPlayoutSeconds === 0) {
        atPlayoutSeconds = episode.duration;
      }

      updateForm({ trimmed_duration: atPlayoutSeconds });
    },
    [episode.duration, updateForm],
  );

  const playerOptions = React.useMemo(
    () => ({
      cuepoints: data.cuepoints ? formatCuepoints(data.cuepoints) : [],
      trimPoint: data.trimmed_duration,
      addCuepoint: addCuepoint,
      removeNearestCuepoint: removeNearestCuepoint,
      trimVideo: trimVideo,

      changeSource: changeSource,
      controls: {
        editCuepoints: isEditing,
        playoutTrimming: isEditing,
        changeSource: isEditing,
        selectSource: true,
        timers: true,
      },

      availableSources: {
        source: !!episode.source_video?.asset_id,
        normalised: !!episode.content_video?.asset_id,
        hls: !!episode.content_video?.hls_path,
        dash: !!episode.content_video?.dash_path,
      },
    }),
    [episode, addCuepoint, data.cuepoints, data.trimmed_duration, isEditing, removeNearestCuepoint, trimVideo],
  );

  // fill out dropdown list options
  React.useEffect(() => {
    if (!RATING_OPTIONS.length) {
      axios
        .get("/api/ratings")
        .then((resp) => {
          RATING_OPTIONS = resp.data;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (!GENRE_OPTIONS.length) {
      axios
        .get("/api/genres")
        .then((resp) => {
          const imdbGenres = resp.data.imdb.map((genre) => ({
            label: genre.label,
            value: genre.value,
            subgenres: genre.subgenres.map((subgenre) => ({ label: subgenre.label, value: subgenre.value })),
          }));

          const freeviewGenres = resp.data.freeview.map((genre) => ({
            label: genre.label,
            value: genre.value,
          }));

          GENRE_OPTIONS = {
            imdb: imdbGenres,
            freeview: freeviewGenres,
          };
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  //  set default form values
  React.useEffect(() => {
    if (episode) {
      updateForm({
        imdb_genre: episode?.genre?.imdb?.imdb_genre
          ? { label: episode.genre.imdb.imdb_genre.label, value: episode.genre.imdb.imdb_genre.value }
          : null,
        imdb_subgenre: episode?.genre?.imdb?.imdb_subgenre
          ? { label: episode.genre.imdb.imdb_subgenre.label, value: episode.genre.imdb.imdb_subgenre.value }
          : null,
        freeview_genre: episode?.genre?.freeview?.freeview_genre
          ? {
              label: episode.genre.freeview.freeview_genre.label,
              value: episode.genre.freeview.freeview_genre.value,
            }
          : null,
        summary: {
          long_summary: episode.summary?.long_summary ?? "",
          short_summary: episode.summary?.short_summary ?? "",
        },
      });
    }
  }, [episode, updateForm]);

  React.useEffect(() => {
    // ensure we are never stacking overlays
    if (isEditing) {
      setTrashing(false);
      setRestoring(false);
      updateForm({
        cuepoints: episode.cuepoints ?? [],
      });
    }
  }, [isEditing, episode?.cuepoints, updateForm]);

  if (isLoading) {
    return (
      <div className="content-page__loader">
        <Loader />
      </div>
    );
  }

  function onSaveEdits() {
    setSaving(true);

    if (!areProgramCuepointsSpaced(form)) {
      setSaving(false);
      return;
    }

    const payload = { ...form };
    // remove subgenre from payload if not set
    if (!payload.imdb_subgenre) {
      delete payload.imdb_subgenre;
    }

    axios
      .patch(`/api/episodes/${episode.episode_guid}?additional=assets`, payload)
      .then((resp) => {
        toast.success("Episode successfully updated");
        toggleEditMode();
        invalidateCache();
        setEpisode(resp.data);
      })
      .catch((error) => {
        toast.error("Error attempting to update Episode");
        console.error(error);
      })
      .finally(() => setSaving(false));
  }

  function onSetRestricted(restrict = false) {
    setSaving(true);

    axios
      .post(`/api/episodes/${episode.episode_guid}/${restrict ? "restrict" : "unrestrict"}`)
      .then((resp) => {
        toast.success(`Episode ${restrict ? "restricted" : "unrestricted"} successfully`);
        setEpisode((prev) => ({
          ...prev,
          restricted_at: resp.data.restricted_at,
        }));
      })
      .catch((error) => {
        toast.error("Error updating restricted status on Episode");
        console.error(error);
      })
      .finally(() => setSaving(false));
  }

  function getSubgenresForGenre(genre) {
    if (!genre) {
      return [];
    }

    const foundGenre = GENRE_OPTIONS.imdb.find((genreItem) => genreItem.value === genre.value);
    if (!foundGenre) {
      return [];
    }

    return foundGenre.subgenres;
  }

  function openCastCrewDialog() {
    setShowCastCrew(true);
  }

  function closeCastCrewDialog() {
    setShowCastCrew(false);
  }

  function changeSource() {
    setChangeSourceOpen(true);
  }

  function changeSourceClosed() {
    setChangeSourceOpen(false);
  }

  function updateGenre(type, input) {
    switch (type) {
      case "imdb_genre":
        updateForm({
          imdb_genre: input,
          imdb_subgenre: null,
        });
        break;

      case "imdb_subgenre":
        updateForm({
          imdb_subgenre: input,
        });
        break;

      case "freeview_genre":
        updateForm({
          freeview_genre: input,
        });
        break;
    }
  }

  function updateCastCrew(key, value, index) {
    const newCastCrew = data.cast_crew.map((member, ccIndex) => {
      if (ccIndex === index) {
        member[key] = value;
      }

      return member;
    });

    updateForm({
      cast_crew: newCastCrew,
    });
  }

  function updateRating(label, event) {
    const newRating = event.value;
    updateForm({
      rating: {
        ...data.rating,
        [`${label}_rating_id`]: newRating,
      },
    });
  }

  function updateSummary(label, event) {
    const newSummary = event.target.value;
    updateForm({
      summary: {
        ...form.summary,
        [label]: newSummary,
      },
    });
  }

  function addCastCrew(castCrew) {
    updateForm({ cast_crew: [...data.cast_crew, castCrew] });
  }

  function removeCastCrew(index) {
    updateForm({ cast_crew: [...data.cast_crew.slice(0, index), ...data.cast_crew.slice(index + 1)] });
  }

  function setTranscoding(isOpen, type) {
    setTranscodingDialog({ isOpen, type });
  }

  return (
    !isLoading &&
    episode && (
      <React.Fragment>
        <div className="tabbed-content__actions">
          {isSaving ? (
            <Loader height={35} width={35} />
          ) : (
            <AppHeadingActions
              leftActions={[
                {
                  component: () => (
                    <button
                      className="btn btn--icon"
                      onClick={() => {
                        if (!["processing", "completed"].includes(episode?.content_video?.hls_status)) {
                          setTranscoding(true, "hls");
                        }
                        return false;
                      }}
                    >
                      <TranscodingStatusIcon
                        status={episode?.content_video?.hls_status}
                        appendTextOptions={{ failed: "Click to transcode", initialised: "Click to transcode" }}
                        small={true}
                      />
                    </button>
                  ),
                },
                {
                  component: () => (
                    <button
                      className="btn btn--icon"
                      onClick={() => {
                        if (!["processing", "completed"].includes(episode?.content_video?.dash_status)) {
                          setTranscoding(true, "dash");
                        }
                        return false;
                      }}
                    >
                      <TranscodingStatusIcon
                        status={episode?.content_video?.dash_status}
                        appendTextOptions={{ failed: "Click to transcode", initialised: "Click to transcode" }}
                        small={true}
                        type="dash"
                      />
                    </button>
                  ),
                },
              ]}
              rightActions={[
                {
                  when: !!episode.restricted_at && !isEditing,
                  icon: LockRoundedIcon,
                  balloonLabel: "Unrestrict content",
                  onClick: onSetRestricted.bind(null, false),
                },
                {
                  when: !episode.restricted_at && !isEditing,
                  icon: NoEncryptionGmailerrorredRoundedIcon,
                  balloonLabel: "Restrict content",
                  onClick: onSetRestricted.bind(null, true),
                },
                {
                  when: isEditing,
                  icon: SaveRoundedIcon,
                  balloonLabel: "Save",
                  onClick: onSaveEdits,
                },
                {
                  when: isEditing,
                  icon: CancelRoundedIcon,
                  balloonLabel: "Cancel",
                  onClick: cancelEditing,
                },
                {
                  when: !isEditing && episode.episode_active === 0,
                  icon: RestoreFromTrashRoundedIcon,
                  balloonLabel: "Restore content",
                  onClick: () => setRestoring(true),
                },
                {
                  when: !isEditing && episode.episode_active === 1,
                  icon: DeleteForeverRoundedIcon,
                  balloonLabel: "Mark as inactive",
                  onClick: () => setTrashing(true),
                },
                {
                  when: !isEditing && episode.episode_active === 1,
                  icon: EditRoundedIcon,
                  balloonLabel: "Edit",
                  onClick: () => toggleEditMode(),
                },
              ]}
            />
          )}
        </div>
        <div className="content-page__media-row">
          <div className="content-page__media-row__media">
            <ContentCard noPadding>
              {!episode || !Object.keys(episode).length || isSwitchingPlayers ? (
                <Loader />
              ) : (
                <ContentPlayer program={episode} playerOptions={playerOptions} />
              )}
            </ContentCard>
          </div>
          <div className="content-page__media-row__description">
            <ContentCard>
              <h1 className="content-page__title content-page__title--small">
                {`Season ${episode?.season?.season_number ?? ""} Episode ${episode?.episode_number ?? ""}${episode?.episode_name ? `: ${episode.episode_name}` : ""}${!episode.restricted_at ? "" : " 🔒"}`}
              </h1>
              <h3 className="content-page__sub-title">Run time</h3>
              <p className="content-page__text">
                {episode.duration
                  ? secondsToHourMinutesSeconds(episode.duration)
                  : episode.video_with_trashed
                    ? secondsToHourMinutesSeconds(episode.video_with_trashed.duration)
                    : "N/A"}
              </p>
              <h3 className="content-page__sub-title">Short Summary</h3>
              {isEditing ? (
                <textarea
                  value={data.summary?.short_summary ?? "None"}
                  onChange={updateSummary.bind(null, "short_summary")}
                  maxLength={255}
                />
              ) : (
                <div className="content-page__text">{episode.summary?.short_summary || "None"}</div>
              )}
              <h3 className="content-page__sub-title">Long Summary</h3>
              {isEditing ? (
                <textarea
                  value={data.summary?.long_summary ?? "None"}
                  onChange={updateSummary.bind(null, "long_summary")}
                />
              ) : (
                <div className="content-page__text">{episode.summary?.long_summary || "None"}</div>
              )}
            </ContentCard>
          </div>
        </div>
        <div className="gw">
          <div className="g g--1-of-3">
            <ContentCard>
              <div className="content-page__section">
                <div className="content-page__list">
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">IMDb Genre</div>
                    {isEditing ? (
                      <Select
                        placeholder="Select a Genre"
                        name="genre"
                        options={GENRE_OPTIONS.imdb}
                        value={getActiveGenre("imdb_genre")}
                        onChange={(input) => updateGenre("imdb_genre", input)}
                        classNamePrefix="react-select"
                        containerClass="react-select-container--small react-select-container--light"
                        menuPlacement="auto"
                      />
                    ) : (
                      <div className="content-page__list__value">{episode.genre?.imdb?.imdb_genre?.label ?? "N/A"}</div>
                    )}
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">IMDb Subgenre</div>
                    {isEditing ? (
                      <Select
                        placeholder="Select a Genre"
                        name="subgenre"
                        options={getSubgenresForGenre(getActiveGenre("imdb_genre"))}
                        value={getActiveGenre("imdb_subgenre")}
                        onChange={(input) => updateGenre("imdb_subgenre", input)}
                        classNamePrefix="react-select"
                        containerClass="react-select-container--small react-select-container--light"
                        menuPlacement="auto"
                      />
                    ) : (
                      <div className="content-page__list__value">
                        {episode.genre?.imdb?.imdb_subgenre?.label ?? "N/A"}
                      </div>
                    )}
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">Freeview Genre</div>
                    {isEditing ? (
                      <Select
                        placeholder="Select a Genre"
                        name="genre"
                        options={GENRE_OPTIONS.freeview}
                        value={getActiveGenre("freeview_genre")}
                        onChange={(input) => updateGenre("freeview_genre", input)}
                        classNamePrefix="react-select"
                        containerClass="react-select-container--small react-select-container--light"
                        menuPlacement="auto"
                      />
                    ) : (
                      <div className="content-page__list__value">
                        {episode.genre?.freeview?.freeview_genre?.label ?? "N/A"}
                      </div>
                    )}
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">Us TV Rating</div>
                    {isEditing ? (
                      <Select
                        options={RATING_OPTIONS.us_tv}
                        placeholder="Select a Rating"
                        name="us_tv_rating"
                        value={getActiveRating("us_tv")}
                        onChange={(input) => updateRating("us_tv", input)}
                        classNamePrefix="react-select"
                        containerClass="react-select-container--small react-select-container--light"
                        menuPlacement="auto"
                      />
                    ) : (
                      <div className="content-page__list__value">{episode.rating?.us_tv_rating ?? "N/A"}</div>
                    )}
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">MPAA Rating</div>
                    {isEditing ? (
                      <Select
                        options={RATING_OPTIONS.mpaa}
                        placeholder="Select a Rating"
                        name="mpaa_rating"
                        value={getActiveRating("mpaa")}
                        onChange={(input) => updateRating("mpaa", input)}
                        classNamePrefix="react-select"
                        containerClass="react-select-container--small react-select-container--light"
                        menuPlacement="auto"
                      />
                    ) : (
                      <div className="content-page__list__value">{episode.rating?.mpaa_rating ?? "N/A"}</div>
                    )}
                  </div>
                  <div className="content-page__list__item">
                    <div className="content-page__list__label">BBFC Rating</div>
                    {isEditing ? (
                      <Select
                        options={RATING_OPTIONS.bbfc}
                        placeholder="Select a Rating"
                        name="bbfc_rating"
                        value={getActiveRating("bbfc")}
                        onChange={(input) => updateRating("bbfc", input)}
                        classNamePrefix="react-select"
                        containerClass="react-select-container--small react-select-container--light"
                        menuPlacement="auto"
                      />
                    ) : (
                      <div className="content-page__list__value">{episode.rating?.bbfc_rating ?? "N/A"}</div>
                    )}
                  </div>
                </div>
              </div>
            </ContentCard>
          </div>
          <div className="g g--1-of-3">
            <ContentCard>
              <CuepointMeta
                metadata={episode}
                isEditing={isEditing}
                updateForm={updateForm}
                form={form}
                classes={{
                  heading: "content-page__sub-title",
                }}
              />
            </ContentCard>
          </div>
          <div className="g g--1-of-3">
            <ContentCard>
              <div className="content-page__sub-title">
                Cast & Crew
                {isEditing ? (
                  <button className="btn btn--icon" onClick={openCastCrewDialog}>
                    <AddCircleRoundedIcon />
                  </button>
                ) : null}
              </div>
              {isEditing ? (
                <div className="cast-crew">
                  {data.cast_crew?.map((person, index) => (
                    <div className="cast-crew__item" key={index}>
                      <div className="cast-crew__role">
                        <input
                          type="text"
                          onChange={(e) => updateCastCrew("role", e.target.value, index)}
                          value={person.role}
                        />
                      </div>
                      <div className="cast-crew__name">
                        <input
                          type="text"
                          onChange={(e) => updateCastCrew("name", e.target.value, index)}
                          value={person.name}
                        />
                      </div>
                      <div className="cast-crew__actions">
                        <button className="cast-crew__action-btn" onClick={() => removeCastCrew(index)}>
                          <CancelRoundedIcon />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : episode.cast_crew?.length ? (
                <div className="content-page__list">
                  {episode.cast_crew.map((person, index) => (
                    <div className="content-page__list__item" key={index}>
                      <div className="content-page__list__label">{person.role}</div>
                      <div className="content-page__list__value">{person.name}</div>
                    </div>
                  ))}
                </div>
              ) : (
                "N/A"
              )}
            </ContentCard>
          </div>
        </div>
        <DialogForm
          isOpen={showCastCrewDialog}
          onClose={closeCastCrewDialog}
          onCancel={closeCastCrewDialog}
          onSubmit={addCastCrew}
          title="Add Cast / Crew"
          fields={[
            {
              label: "Role",
              type: "text",
              name: "role",
            },
            {
              label: "Name",
              type: "text",
              name: "name",
            },
          ]}
        />
        <ChangeVideoSourceDialog
          contentType="episode"
          contentGuid={episode.episode_guid}
          onClose={changeSourceClosed}
          isOpen={isChangeSourceOpen}
        />
        <GenericDeleteDialog
          isOpen={isTrashing}
          body={
            "This will mark this episode and all of its related content as inactive, are you sure you wish to continue?"
          }
          onClose={() => {
            setTrashing(false);
          }}
          endpoint={`/api/episodes/${episode.episode_guid}`}
          onComplete={(result, error) => {
            if (error) {
              console.error(error);
            } else if (result) {
              setTrashing(false);
              toast.success("Successfully marked this episode as inactive.");
              setTimeout(() => navigate(0), 1500);
            }
          }}
        />
        <GenericRestoreDialog
          isOpen={isRestoring}
          body={"This action will restore this episode and some of its related data. Do you sure you wish to continue?"}
          onClose={() => {
            setRestoring(false);
          }}
          endpoint={`/api/episodes/${episode.episode_guid}/restore`}
          onComplete={(result, error) => {
            if (error) {
              console.error(error);
            } else if (result) {
              setRestoring(false);
              toast.success("Successfully restored this episode.");
              setTimeout(() => navigate(0), 1500);
            }
          }}
        />
        <QueueTranscodeDialog
          contentId={episode.episode_guid}
          contentType={"episode"}
          onClose={() => setTranscoding(false, "")}
          onSuccess={() => {
            setTranscoding(false, "");
            toast.success(`${transcodingDialog.type.toUpperCase()} transcoding queued`);
          }}
          isOpen={transcodingDialog.isOpen}
          transcodeType={transcodingDialog.type}
        />
      </React.Fragment>
    )
  );
}
