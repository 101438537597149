import differenceInSeconds from "date-fns/differenceInSeconds";
import { startOfDay } from "date-fns";
import { secondsToHourMinutes, secondsToHourMinutesSeconds } from "../../../common/duration-formatting.js";

export function relativeSecondsToHourMinutes(planDate, base, seconds) {
  const secondsToBase = differenceInSeconds(base, startOfDay(planDate));
  return secondsToHourMinutes(secondsToBase + seconds);
}

export function relativeSecondsToHourMinutesSeconds(planDate, base, seconds) {
  const secondsToBase = differenceInSeconds(base, startOfDay(planDate));
  return secondsToHourMinutesSeconds(secondsToBase + seconds);
}
