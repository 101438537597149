import React from "react";
import { getGenres } from "../../../requests/api-requests.js";
import Select from "../../../common/select.jsx";

let __GENRE_CACHE__ = [];

function GenreFilter({ onChange, selectedGenres }) {
  const [genres, setGenres] = React.useState(__GENRE_CACHE__);
  React.useEffect(() => {
    if (!genres.length) {
      getGenres()
        .then((data) => {
          __GENRE_CACHE__ = data.imdb.map((item) => ({
            label: item.label,
            value: item.value,
            id: item.genre_imdb_id,
            subgenres: item.subgenres.map((subgenre) => ({
              label: subgenre.label,
              value: subgenre.value,
              id: subgenre.subgenre_imdb_id,
            })),
          }));
          setGenres(__GENRE_CACHE__);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [genres]);

  // potentially patch incoming data
  React.useEffect(() => {
    let patched = false;

    const fixed = selectedGenres.map((gen) => {
      let label = gen.label;
      let value = +gen.value;

      if (!label) {
        label = genres.find((genre) => genre.id === value)?.label ?? "-";
        if (label !== "-") {
          patched = true;
        }
      }

      return { label, value };
    });

    if (patched && fixed.length) {
      onChange(fixed[0]);
    }
  }, [selectedGenres, genres, onChange]);

  return (
    <Select
      placeholder={"Genres"}
      name={"genre"}
      isSearchable={true}
      onChange={onChange}
      options={genres}
      containerClass="react-select-container--light react-select-container--small"
      value={selectedGenres.length ? selectedGenres[0] : null}
    />
  );
}

export function SubgenreFilter({ onChange, selectedGenres, selectedSubgenres }) {
  const [genres, setGenres] = React.useState(__GENRE_CACHE__);
  React.useEffect(() => {
    if (!genres.length) {
      getGenres()
        .then((data) => {
          __GENRE_CACHE__ = data.imdb.map((item) => ({
            label: item.label,
            value: item.value,
            id: item.genre_imdb_id,
            subgenres: item.subgenres.map((subgenre) => ({
              label: subgenre.label,
              value: subgenre.value,
              id: subgenre.subgenre_imdb_id,
            })),
          }));
          setGenres(__GENRE_CACHE__);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [genres]);

  const validGenreValues = selectedGenres.map((sg) => sg.value);
  const options = genres
    .filter((genre) => validGenreValues.includes(genre.value))
    .map((genre) => genre.subgenres)
    .flat();

  // potentially patch data
  React.useEffect(() => {
    let patched = false;

    const fixed = selectedSubgenres.map((subgen) => {
      const genreValues = selectedGenres.map((sg) => +sg.value);
      const availableGenres = genres
        .filter((genre) => genreValues.includes(genre.id))
        .map((genre) => genre.subgenres)
        .flat();

      let label = subgen.label;
      let value = +subgen.value;

      if (!label) {
        label = availableGenres.find((subgenre) => subgenre.id === value)?.label ?? "-";
        if (label !== "-") {
          patched = true;
        }
      }

      return { label, value };
    });

    if (patched && fixed.length) {
      onChange(fixed[0]);
    }
  }, [selectedSubgenres, genres, selectedGenres, onChange]);

  return (
    <Select
      placeholder={"Subgenres"}
      name={"subgenre"}
      isSearchable={true}
      onChange={onChange}
      options={options}
      containerClass="react-select-container--light react-select-container--small"
      value={selectedSubgenres.length ? selectedSubgenres[0] : null}
    />
  );
}

export default GenreFilter;
