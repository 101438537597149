import React from "react";
import useApiRequest from "../hooks/use-api-request.js";
import { useParams } from "react-router-dom";
import ChannelForm from "./manage-channel/components/channel-form.jsx";
import { toast } from "react-toastify";
import formatApiErrors from "../functions/format-api-errors.js";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import axios from "../requests/axios.js";

const defaultFormState = {
  name: "",
  display_name: "",
  url: "",
  genre: [],
  language: "",
  regions: [],
  rating: "",
  description: "",
  landscape: "",
  portrait: "",
  square: "",
  playouts: [],
  provider_ids: [],
};

function verifyForm(form) {
  let formErrors = {};

  if (!form.name) {
    formErrors = { ...formErrors, name: "Name is required." };
  }

  if (!form.display_name) {
    formErrors = { ...formErrors, display_name: "Display Name is required." };
  }

  if (!form.url) {
    formErrors = { ...formErrors, url: "URL is required." };
  }

  if (Object.keys(formErrors).length) {
    formErrors = { ...formErrors, api: "Errors in fields above. Please fix and retry." };
  }

  return formErrors;
}

export default function ManageChannelPage({ create }) {
  // state
  const [form, setForm] = React.useState(defaultFormState);
  const { channelGuid } = useParams();
  const [errors, setErrors] = React.useState({});

  // hooks
  const {
    isLoading: providersLoading,
    response: providersResponse,
    // error: providersError,
  } = useApiRequest("/api/providers");

  const {
    isLoading: channelLoading,
    response: channelResponse,
    // error: channelError,
  } = useApiRequest(create ? "" : `/api/channels/${channelGuid}`);

  const {
    isLoading: distributionTypesLoading,
    response: distributionTypesResponse,
    // error: distributionTypesError,
  } = useApiRequest("/api/stream-distributions/distribution-types");

  const {
    isLoading: ssaiTypesLoading,
    response: ssaiTypesResponse,
    // error: ssaiTypesError,
  } = useApiRequest("/api/stream-distributions/ssai-types");

  React.useEffect(() => {
    if (!create && channelResponse.data) {
      const channel = channelResponse.data;
      setForm({
        name: channel.name,
        display_name: channel.display_name,
        url: channel.url,
        genre: channel?.genre ? channel.genre.split(", ") : [],
        language: channel.language ?? "",
        regions: channel?.regions ? channel.regions.split(",") : [],
        rating: channel.rating ?? "",
        description: channel.description ?? "",
        landscape: channel.art.landscape,
        portrait: channel.art.portrait,
        square: channel.art.square,
        playouts: channel.playouts,
        provider_ids: channel.providers?.map((p) => p.provider_id) ?? [],
      });
    }
  }, [channelResponse.data, create]);

  // functions

  const onSubmitFail = React.useCallback(
    (error) => {
      console.error(error);
      if (error.status === 422) {
        setErrors(formatApiErrors(error.data.errors));
      } else if (error.status === 500 || error.status === 404) {
        setErrors({ api: "There was an error editing this channel. Please contact support." });
      } else if (error.status === 403) {
        setErrors({ api: "Insufficient permissions to add a Channel." });
      }
    },
    [setErrors],
  );

  const onSave = React.useCallback(() => {
    setErrors({});

    const formErrors = verifyForm(form);
    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    const payload = { ...form };
    payload.regions = payload.regions.join(",");
    payload.genre = payload.genre.join(", ");

    // process final breaks
    if (form.channel_breaks && Array.isArray(form.channel_breaks)) {
      const newBreaks = form.channel_breaks
        .filter((brk) => brk.checked)
        .map((brk, idx, breakArray) => {
          // set end to next end date or end of day
          const next = breakArray.slice(idx + 1).findIndex((innerBreak) => innerBreak.checked === true);
          brk.end = next !== -1 ? breakArray[next + idx + 1].start : "24:00:00";

          return {
            start: brk.start,
            end: brk.end,
            type: "channel",
          };
        });
      payload.channel_breaks = newBreaks;
    }

    if (!create) {
      // Update Channel
      axios
        .post(`/api/channels/${channelResponse.data?.channel_id}`, payload)
        .then(() => {
          return toast.success("Successfully saved channel");
        })
        .catch(onSubmitFail);
    } else if (create) {
      // Create Channel
      axios
        .post(`api/channels`, payload)
        .then(() => {
          toast.success("Successfully saved channel");
        })
        .catch(onSubmitFail);
    }
  }, [channelResponse.data?.channel_id, form, onSubmitFail, create]);

  // on render variables
  const isLoading = create ? false : channelLoading || providersLoading || distributionTypesLoading || ssaiTypesLoading;

  // TODO: pass this to app body once error support is implemented
  // const hasError = providersError || channelError || distributionTypesError || ssaiTypesError;

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/admin/",
            title: "Admin",
          },
          {
            link: "/admin/channels",
            title: "Channels",
          },
          {
            title: create ? "Create Channel" : channelResponse.data?.display_name ?? "...",
            link: "",
          },
        ]}
        rightActions={[
          {
            balloonLabel: "Save",
            icon: SaveRoundedIcon,
            onClick: onSave,
          },
        ]}
      />
      <AppBody loading={isLoading}>
        {!isLoading && (
          <ChannelForm
            action={create ? "create" : "edit"}
            onComplete={() => null}
            channel={channelResponse.data}
            providers={providersResponse.data}
            form={form}
            setForm={setForm}
            errors={errors}
            setErrors={setErrors}
            distributionTypes={distributionTypesResponse.data}
            ssaiTypes={ssaiTypesResponse.data}
          />
        )}
      </AppBody>
    </React.Fragment>
  );
}
